<template>
  <div class="modal-menu-detail-container">
    <div class="modal-menu-detail-header-nav">
      <div @click="goBack()" class="modal-menu-detail-container1">
        <img
          :alt="image_alt"
          :src="image_src"
          class="modal-menu-detail-image"
        />
        <h1 class="modal-menu-detail-text text-xl font-black">{{ title }}</h1>
      </div>
      <!-- <div @click="toggleDetail()">
        <img :alt="image_alt1" :src="image_src1" class="modal-menu-detail-image1" />
      </div> -->
    </div>
    <div class="overflow-y-auto w-full">
      <img :src="cover" class="bg-img-hero w-full hidden md:block object-cover" />
      <img :src="coverMobile" class="bg-img-hero w-full md:hidden object-cover object-center" />
      <div class="modal-menu-detail-wrap-menu mb-10">
        <menu-item-product
          v-for="child in childs"
          :key="child.id"
          rootClassName="rootClassName"
          :child="child"
          :imgSrc="child.photo"
          :imgMobile="child.img_mobile"
          :title="child.title"
        ></menu-item-product>
      </div>
    </div>
  </div>
</template>

<script>
import MenuItemProduct from "./menu-item-product";

export default {
  name: "ModalMenuDetail",
  props: {
    image_alt1: {
      type: String,
      default: "image",
    },
    image_src1: {
      type: String,
      default: "/playground_assets/close_tosca.svg",
    },
    image_alt: {
      type: String,
      default: "image",
    },
    heading: {
      type: String,
      default: "Living Room",
    },
    image_src: {
      type: String,
      default: "/playground_assets/arrow-left-black.svg",
    },
  },
  components: {
    MenuItemProduct,
  },
  computed: {
    title() {
      return this.$store.state.navBar.dropDownMobile.title;
    },
    cover() {
      return this.$store.state.navBar.dropDownMobile.photo;
    },
    coverMobile() {
      return this.$store.state.navBar.dropDownMobile.img_mobile;
    },
    childs() {
      return this.$store.state.navBar.dropDownMobile.child;
    }
  },
  methods: {
    toggleModal() {
      this.$store.commit("toggleModal");
    },
    toggleDetail() {
      this.$store.commit("toggleModalDetail");
    },
    goBack() {
      this.$store.commit("toggleModalDetail");
      this.$store.commit("toggleModal");
    },
  },
};
</script>

<style scoped>
.bg-img-hero {
  height: 213px;
  object-fit: cover; /* cover image */
  object-position: left; /* Center the image within the element */
}
.modal-menu-detail-container {
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  background: white;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999;
  overflow: scroll;
}
.modal-menu-detail-header-nav {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #eae3d9;
  height: 56px;
}
.modal-menu-detail-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
}
.modal-menu-detail-image {
  width: 100px;
  object-fit: cover;
}
.modal-menu-detail-image1 {
  width: 100px;
  object-fit: cover;
}
.modal-menu-detail-wrap-menu {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
  grid-row-gap: 20px;
}
@media (max-width: 991px) {
  .modal-menu-detail-header-nav {
    padding: var(--dl-space-space-unit);
    align-items: center;
    justify-content: space-between;
  }
  .modal-menu-detail-container1 {
    width: auto;
    height: auto;
  }
  .modal-menu-detail-image {
    width: auto;
    height: 24px;
  }
  .modal-menu-detail-text {
    font-family: Lora;
    margin-left: var(--dl-space-space-oneandhalfunits);
  }
  .modal-menu-detail-image1 {
    width: 26px;
    height: 26px;
  }
  .modal-menu-detail-wrap-menu {
    width: 100%;
    height: 100%;
    padding: var(--dl-space-space-unit);
    margin-top: -64px;
    flex-direction: column;
  }
}
</style>
