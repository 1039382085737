<template>
  <div class="spacer-w16-spacer-16" v-bind:class="rootClassName"></div>
</template>

<script>
export default {
  name: 'SpacerW16',
  props: {
    rootClassName: String,
  },
}
</script>

<style scoped>
.spacer-w16-spacer-16 {
  width: 16px;
}











</style>
