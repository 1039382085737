<template>
  <div class="flex flex-col min-h-screen">
    <app-navbar />
    <main
      class="main"
      :style="{
        paddingTop: headerHeight + 'px',
      }"
    >
      <transition name="page" appear>
        <router-view />
      </transition>
    </main>
  </div>
</template>

<script>
  import AppNavbar from './components/navbar';
  export default {
    name: 'App',
    components: {
      AppNavbar,
    },
    data: () => ({
      header: null,
      headerHeight: 0,
    }),
    computed: {
      isAuthenticated() {
        return this.$store.state.isAuthenticated;
      },
    },
    async mounted() {
      window.addEventListener('resize', this.setScreen);
      this.divElement = document.querySelector('#header');
      this.setHeight();
      const SELF = this;
      window.addEventListener(
        'resize',
        function () {
          SELF.setHeight();
        },
        true
      );
      if (this.isAuthenticated) {
        await this.$store.dispatch('getCart');
      }
    },
    methods: {
      setHeight() {
        const elemHeight = this.divElement.offsetHeight;
        this.headerHeight = elemHeight;
      },
      setScreen() {
        this.$store.commit('setScreen', { width: window.innerWidth });
      },
    },
  };
</script>
