<template>
<svg width="101" height="48" viewBox="0 0 101 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<path d="M33.2739 8.18638C32.5984 8.19508 31.9334 8.01817 31.3506 7.67473C30.7967 7.35554 30.3356 6.89605 30.0131 6.34198C29.7029 5.81181 29.5399 5.20765 29.541 4.59253C29.5392 3.96857 29.7002 3.35504 30.0079 2.81319C30.3259 2.24927 30.7875 1.78074 31.3454 1.45583C31.9288 1.11395 32.5934 0.937161 33.2686 0.94418C33.9616 0.932469 34.6448 1.10925 35.2461 1.45583C35.8057 1.77957 36.2688 2.2483 36.5871 2.81319C36.8997 3.35357 37.065 3.96736 37.0661 4.59253C37.069 5.21089 36.9053 5.81848 36.5923 6.35077C36.271 6.90513 35.8103 7.36475 35.2565 7.68352C34.6559 8.03175 33.9723 8.20865 33.2791 8.19517L33.2739 8.18638ZM30.1739 12.6593H36.1919V35.3407H30.1739V12.6593Z" :fill="iconColor"/>
<path d="M88.9536 36.0334C86.8052 36.0615 84.6885 35.5102 82.8237 34.4369C81.0297 33.4113 79.5424 31.9199 78.5174 30.1187C77.471 28.2846 76.9313 26.2024 76.9543 24.0879C76.9349 21.9734 77.4743 19.8915 78.5174 18.0554C79.5398 16.2421 81.0324 14.7418 82.8359 13.7143C84.7142 12.6683 86.8253 12.1181 88.9723 12.115C91.1193 12.112 93.2321 12.6561 95.1132 13.6967C96.9146 14.723 98.4072 16.2191 99.4335 18.0273C100.485 19.8694 101.028 21.9605 101.007 24.0844C101.033 26.2059 100.498 28.2962 99.4563 30.1416C98.4351 31.9472 96.9415 33.437 95.1377 34.4492C93.253 35.5141 91.1222 36.06 88.9606 36.0317L88.9536 36.0334ZM88.9851 30.3895C90.0732 30.4025 91.1444 30.1187 92.085 29.5684C93.0088 29.029 93.7696 28.2475 94.2862 27.3073C94.8288 26.3211 95.106 25.2096 95.0905 24.0827C95.1016 22.9562 94.8247 21.8458 94.2862 20.858C93.8912 20.1488 93.3596 19.5259 92.7225 19.0255C92.0855 18.5251 91.3557 18.1573 90.5758 17.9435C89.7959 17.7298 88.9814 17.6743 88.18 17.7805C87.3786 17.8866 86.6062 18.1522 85.9079 18.5618C84.9824 19.1106 84.2251 19.9051 83.7189 20.858C83.1828 21.8485 82.9072 22.9603 82.9181 24.0879C82.9016 25.2137 83.1751 26.3245 83.7119 27.3125C84.2188 28.2518 84.9716 29.0337 85.8887 29.5736C86.8249 30.1252 87.8931 30.4091 88.9781 30.3947" :fill="iconColor"/>
<path d="M73.0989 29.818V12.6593H67.0879V29.8198H63.8061C63.2961 29.8198 62.8011 29.6459 62.4019 29.3266C62.0028 29.0073 61.723 28.5613 61.6084 28.0615L59.3739 18.1556L59.1728 17.2624C58.8824 15.9567 58.1585 14.7894 57.1202 13.9527C56.082 13.116 54.7913 12.6598 53.4608 12.6593H48.8922V9.49451C48.8922 8.16176 49.1685 7.15429 49.721 6.47209C50.2735 5.78989 51.1016 5.44938 52.2055 5.45055C52.5377 5.45055 52.8331 5.45055 53.0936 5.4611C53.3542 5.47165 53.6269 5.4822 53.9101 5.49802V0.0474725C53.5465 0.0474725 53.2318 0.0474725 52.9643 0.0246154C52.6968 0.00175824 52.3646 0 51.9694 0C50.045 0 48.3992 0.325275 47.0319 0.975824C45.7042 1.59065 44.6147 2.62813 43.932 3.92791C43.2326 5.24425 42.883 6.87824 42.883 8.82989V12.6593H39.7358V18.1802H42.883V35.3407H48.894V18.1802H51.9624C52.4723 18.18 52.9672 18.3538 53.3662 18.6732C53.7651 18.9926 54.0444 19.4387 54.1584 19.9385L56.5957 30.7516C56.8892 32.0546 57.6144 33.2186 58.6523 34.0524C59.6902 34.8863 60.9792 35.3406 62.3077 35.3407H67.0879V38.5055C67.0879 39.8382 66.8116 40.8457 66.2591 41.5279C65.7066 42.2101 64.8785 42.5506 63.7746 42.5494C63.4442 42.5494 63.147 42.5494 62.8865 42.5371C62.6259 42.5248 62.3619 42.516 62.07 42.5002V47.9508C62.4336 47.9508 62.7483 47.9508 63.0176 47.9754C63.2868 48 63.6173 47.9982 64.0107 47.9982C65.9374 47.9982 67.5827 47.673 68.9464 47.0224C70.2733 46.4072 71.3625 45.3706 72.0463 44.0721C72.7457 42.7558 73.0954 41.1212 73.0954 39.1684V35.3407H76.2425V29.8198L73.0989 29.818Z" :fill="iconColor"/>
<path d="M0 35.3407V1.90417H13.7267C15.9822 1.90417 17.9701 2.36835 19.6905 3.2967C21.3712 4.18903 22.7656 5.54253 23.7118 7.2C24.6735 8.87385 25.1549 10.8079 25.156 13.0022C25.156 15.4637 24.568 17.5262 23.3919 19.1894C22.2064 20.8633 20.5618 22.1537 18.659 22.9029C16.6774 23.7116 14.4488 24.116 11.9731 24.116H6.41314V35.3407H0ZM13.1358 18.4193C14.1407 18.4393 15.1341 18.2015 16.0224 17.7283C16.8338 17.2902 17.5018 16.6252 17.9456 15.8136C18.4082 14.9538 18.6425 13.9885 18.6257 13.011C18.6517 12.0283 18.4143 11.0566 17.9386 10.1978C17.4866 9.41644 16.8184 8.78416 16.0154 8.37802C15.1479 7.9404 14.1883 7.71965 13.2179 7.7345H6.41314V18.4211L13.1358 18.4193Z" :fill="iconColor"/>
<path d="M26.0093 35.8295C17.4299 35.8295 15.2829 29.9833 14.1289 26.8431C13.9314 26.3051 13.7443 25.7969 13.5642 25.4031C13.3597 24.9565 12.8806 23.9121 10.3157 23.9121H7.74902V18.3965H10.3157C12.5886 18.3965 16.6711 19.0066 18.5437 23.098C18.8129 23.6853 19.0455 24.3182 19.271 24.9319C20.376 27.9402 21.2484 30.3156 26.0076 30.3156L26.0093 35.8295Z" :fill="iconColor"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="101" height="48" fill="none"/>
</clipPath>
</defs>
</svg>



</template>
<script>
export default {
  props: {
    iconColor: {
      type: String,
      default: "#1F252C",
    },
  },
};
</script>
<style>
</style>