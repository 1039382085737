<template>
  <div
    @click="goPage()"
    class="best-card-best-card z-50 max-lg:w-56 max-lg:flex-shrink-0 lg:flex-1 relative hover:opacity-80"
    v-bind:class="rootClassName"
  >
    <h1
      class="best-card-title text-4xl font-semibold max-lg:text-2xl lg:text-4xl leading-tight"
    >
      {{ title }}
    </h1>
    <div class="best-card-button-detail">
      <span class="best-card-text4">{{ text }}</span>
      <img :alt="image_alt" :src="icon" class="best-card-image" />
    </div>
    <div
      class="best-card-bg-image"
      :style="{
        backgroundImage: `url(${
          photo ? photo : '/playground_assets/best-image1-1000w.png'
        })`,
      }"
    ></div>
  </div>
</template>

<script>
  export default {
    name: 'BestCard',
    props: {
      rootClassName: String,
      image_alt: {
        type: String,
        default: 'image',
      },
      text: {
        type: String,
        default: 'Detail',
      },
      slug: {
        type: String,
      },
      title: {
        type: String,
        default: 'Meja Belajar Ergonomis',
      },
      icon: {
        type: String,
        default: '/playground_assets/arrow-right-black.svg',
      },
      photo: {
        type: [String, Object, Array],
        default:
          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAMFBMVEXp7vG6vsG3u77s8fTCxsnn7O/f5OfFyczP09bM0dO8wMPk6ezY3eDd4uXR1tnJzdBvAX/cAAACVElEQVR4nO3b23KDIBRA0ShGU0n0//+2KmO94gWZ8Zxmr7fmwWEHJsJUHw8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAwO1MHHdn+L3rIoK6eshsNJ8kTaJI07fERPOO1Nc1vgQm2oiBTWJ+d8+CqV1heplLzMRNonED+4mg7L6p591FC+133/xCRNCtd3nL9BlxWP++MOaXFdEXFjZ7r8D9l45C8y6aG0cWtP/SUGhs2d8dA/ZfGgrzYX+TVqcTNRRO9l+fS5eSYzQs85psUcuzk6igcLoHPz2J8gvzWaH/JLS+95RfOD8o1p5CU5R7l5LkfKEp0mQ1UX7hsVXqDpRrifILD/3S9CfmlUQFhQfuFu0STTyJ8gsP3PH7GVxN1FC4t2sbBy4TNRTu7LyHJbqaqKFw+/Q0ncFloo7CjRPwMnCWqKXQZ75El4nKC9dmcJaou9AXOE5UXbi+RGeJygrz8Uf+GewSn9uXuplnWDZJ7d8f24F/s6iq0LYf9olbS3Q8i5oKrRu4S9ybwaQ/aCkqtP3I28QDgeoK7TBya/aXqL5COx67PTCD2grtdOwH+pQV2r0a7YVBgZoKwwIVFQYG6ikMDVRTGByopjD8ATcKb0UhhRTe77sKs2DV7FKSjId18TUEBYVyLhUThWfILHTDqmI85/2RWWjcE/bhP6OD7maT3h20MHsA47JC3PsW0wcwLhv9t0OOPOIkCn21y2bXXwlyylxiYMPk1SuCSmpfK8bNQvIrpAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADwNX4BCbAju9/X67UAAAAASUVORK5CYII=',
      },
    },

    data() {
      return {
        rawemrz: ' ',
        raw109w: ' ',
      };
    },
    methods: {
      goPage() {
        if (this.slug) {
          this.$router.push(`/product-detail/${this.slug}`);
        }
      },
    },
  };
</script>

<style scoped>
  .best-card-best-card {
    flex: 1;
    display: flex;
    padding: var(--dl-space-space-twounits);
    position: relative;
    align-items: flex-start;
    flex-direction: column;
    cursor: pointer;
    height: 100%;
    background-color: white;
    overflow: hidden;
    z-index: 10;
  }
  .best-card-bg-image {
    position: absolute;
    width: 400px;
    height: 400px;
    bottom: -10px;
    z-index: -1;
  }
  @media (max-width: 990px) {
    .best-card-bg-image {
      position: absolute;
      width: 400px;
      height: 250px;
      bottom: -25px;
      z-index: -1;
      right: -130px;
    }
  }
  .best-card-title {
    font-family: Lora;
    white-space: initial;
  }
  .best-card-text {
    font-family: Lora;
  }
  .best-card-text2 {
    font-family: Lora;
  }
  .best-card-text3 {
    font-family: Lora;
  }
  .best-card-button-detail {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    margin-top: var(--dl-space-space-oneandhalfunits);
    align-items: center;
  }
  .best-card-text4 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin-right: var(--dl-space-space-halfunit);
  }
  .best-card-image {
    object-fit: cover;
  }
  .rootClassName {
  }

  @media (max-width: 991px) {
    .best-card-best-card {
      height: 261px;
      width: 214px;
      padding: 16px;
    }
    .best-card-title {
      font-size: 24px;
    }
  }
</style>
