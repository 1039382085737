<template>
  <div @click="goPageDetail(child)" class="menu-item-product-menu-item" v-bind:class="rootClassName">
    <img :alt="imgAlt" :src="imgSrc" class="menu-item-product-image" />
    <span class="menu-item-product-text">{{ title }}</span>
  </div>
</template>

<script>
export default {
  name: 'MenuItemProduct',
  props: {
    rootClassName: String,
    imgAlt: {
      type: String,
      default: 'image',
    },
    imgSrc: {
      type: String,
      default: '/playground_assets/chair.png',
    },
    title: {
      type: String,
      default: 'Chair',
    },
    child: {
      type: Object,
      default: {},
    },
  },
  methods: {
    goPageDetail(item) {
      this.$store.commit('setNavBarDropDown', false);
      this.$store.dispatch('hideDropdown');
      this.$router.push(`/shop/${item.slug}`).then(() => {}).catch(() => {});
      this.$store.commit('setTitlePage', item.title );
      this.$store.commit('setShopAllCategory', item);
    },
  }
}
</script>

<style scoped>
.menu-item-product-menu-item {
  flex: 0 0 auto;
  width: 100%;
  border: 1px solid #EAE3D9;
  display: flex;
  align-items: flex-start;
  background:#fff;
}
.menu-item-product-image {
  width: 100px;
  object-fit: cover;
}





@media(max-width: 991px) {
  .menu-item-product-menu-item {
    height: 100px;
    padding: var(--dl-space-space-unit);
    align-items: center;
  }
  .menu-item-product-image {
    width: var(--dl-size-size-medium);
  }
  .menu-item-product-text {
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
    margin-left: var(--dl-space-space-threeunits);
  }
}
</style>
