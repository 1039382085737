<template>
  <div class="new-magazine-new-magazine">
    <div class="new-magazine-magazine1">
      <div class="new-magazine-cover-img"></div>
      <span class="new-magazine-magazine1text">
        <span class="new-magazine-text"></span>
        <span class="new-magazine-text1">VOL 5 / 2021</span>
      </span>
      <span class="new-magazine-magazine1text2">{{ magazine1Text2 }}</span>
      <div class="new-magazine-wrap-book">
        <div class="new-magazine-book">
          <img :src="image_src" :alt="image_alt" class="new-magazine-image" />
          <span class="new-magazine-text2">{{ text }}</span>
        </div>
        <div class="new-magazine-book1">
          <img :src="image_src1" :alt="image_alt1" class="new-magazine-image1" />
          <span class="new-magazine-text3">{{ text1 }}</span>
        </div>
        <div class="new-magazine-book2">
          <img :src="image_src2" :alt="image_alt2" class="new-magazine-image2" />
          <span class="new-magazine-text4">{{ text2 }}</span>
        </div>
      </div>
      <div class="new-magazine-read-all">
        <span class="new-magazine-read-all-text">{{ readAllText }}</span>
        <img
          :alt="read_allImage_alt"
          :src="read_allImage_src"
          class="new-magazine-read-all-image"
        />
      </div>
    </div>
    <div class="new-magazine-magazine2">
      <div class="new-magazine-row2">
        <blog-card></blog-card>
        <blog-card></blog-card>
        <blog-card></blog-card>
      </div>
      <div class="new-magazine-wrap-more">
        <div class="new-magazine-more-button">
          <span class="new-magazine-wrap-more-text">{{ wrap_moreText }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BlogCard from '../blog-card'

export default {
  name: 'NewMagazine',
  props: {
    magazine1Text2: {
      type: String,
      default: 'PREVIOUS MAGAZINES',
    },
    readAllText: {
      type: String,
      default: 'Read All Magazine',
    },
    read_allImage_src: {
      type: String,
      default: '/playground_assets/arrow-right.svg',
    },
    read_allImage_alt: {
      type: String,
      default: 'image',
    },
    image_src: {
      type: String,
      default: '/playground_assets/sm-book1.png',
    },
    image_alt: {
      type: String,
      default: 'image',
    },
    text: {
      type: String,
      default: 'VOL 4 / 2021',
    },
    image_src1: {
      type: String,
      default: '/playground_assets/sm-book1.png',
    },
    image_alt1: {
      type: String,
      default: 'image',
    },
    text1: {
      type: String,
      default: 'VOL 4 / 2021',
    },
    image_src2: {
      type: String,
      default: '/playground_assets/sm-book1.png',
    },
    image_alt2: {
      type: String,
      default: 'image',
    },
    text2: {
      type: String,
      default: 'VOL 4 / 2021',
    },
    wrap_moreText: {
      type: String,
      default: 'Baca Rubrik Lainnya',
    },
  },
  components: {
    BlogCard,
  },
}
</script>

<style scoped>
.new-magazine-new-magazine {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-fiveunits);
  background-color: #fff;
  margin-top: -1px;
}
.new-magazine-magazine1 {
  flex: 30%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  border-right: 1px solid var(--beige-acsend);
}
.new-magazine-cover-img {
  flex: 0 0 auto;
  width: 100%;
  height: 420px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("/playground_assets/cover-1000w.png");
}
.new-magazine-magazine1text {
  width: 100%;
  font-size: 18px;
  font-style: normal;
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  font-family: Lora;
  font-weight: 700;
}
.new-magazine-text {
  font-family: Lora;
}
.new-magazine-text1 {
  font-family: Lora;
}
.new-magazine-magazine1text2 {
  font-size: 16px;
  align-self: flex-start;
  font-style: normal;
  margin-top: var(--dl-space-space-oneandhalfunits);
  font-family: Montserrat;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.new-magazine-wrap-book {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  grid-row-gap: 10px;
  flex-direction: column;
}
.new-magazine-book {
  flex: 0 0 auto;
  width: 100%;
  border: 1px solid #EAE3D9;
  display: flex;
  align-items: center;
}
.new-magazine-image {
  width: 100px;
  object-fit: cover;
}
.new-magazine-text2 {
  font-size: 16px;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 700;
  margin-left: var(--dl-space-space-unit);
}
.new-magazine-book1 {
  flex: 0 0 auto;
  width: 100%;
  border: 1px solid #EAE3D9;
  display: flex;
  align-items: center;
}
.new-magazine-image1 {
  width: 100px;
  object-fit: cover;
}
.new-magazine-text3 {
  font-size: 16px;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 700;
  margin-left: var(--dl-space-space-unit);
}
.new-magazine-book2 {
  flex: 0 0 auto;
  width: 100%;
  border: 1px solid #EAE3D9;
  display: flex;
  align-items: center;
}
.new-magazine-image2 {
  width: 100px;
  object-fit: cover;
}
.new-magazine-text4 {
  font-size: 16px;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 700;
  margin-left: var(--dl-space-space-unit);
}
.new-magazine-read-all {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.new-magazine-read-all-text {
  font-style: normal;
  font-family: Montserrat;
  font-weight: 700;
  margin-right: 8px;
  letter-spacing: 0.24em;
}
.new-magazine-read-all-image {
  width: 24px;
  object-fit: cover;
}
.new-magazine-magazine2 {
  flex: 70%;
  height: 100%;
  display: flex;
  column-gap: 10px;
  align-items: flex-start;
  padding-left: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
}
.new-magazine-row2 {
  width: 734px;
  height: 414px;
  display: flex;
  column-gap: 10px;
  align-items: flex-start;
}
.new-magazine-wrap-more {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 42px;
}
.new-magazine-more-button {
  flex: 0 0 auto;
  width: 319px;
  height: 48px;
  display: flex;
  align-self: center;
  align-items: center;
  border: 1px solid var(--black-logo);
  flex-direction: column;
  justify-content: center;
}
.new-magazine-wrap-more-text {
  color: var(--black-logo);
  font-size: 16px;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 700;
  letter-spacing: 0.24em;
}


@media only screen and (min-width: 90.063em) and (max-width: 120em) { 
  .new-magazine-cover-img{
    height: 737px;
  }
  .new-magazine-row2{
    width: 100%;
    height: auto;
  }
} 
@media(max-width: 991px) {
  .new-magazine-new-magazine {
    flex-wrap: wrap;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    margin-top: -2px;
    padding-top: 6px;
  }
  .new-magazine-magazine1 {
    flex: 100%;
    border: 0px;
    padding-right: 0px;
  }
  .new-magazine-cover-img {
    width: 100%;
    align-self: center;
    height: 500px;
  }
  .new-magazine-read-all {
    width: 100%;
    margin-bottom: var(--dl-space-space-twounits);
    justify-content: flex-start;
  }
  .new-magazine-magazine2 {
    flex: 100%;
    flex-wrap: wrap;
    padding-left: 0px;
  }
  .new-magazine-row2 {
    flex: 1;
    width: 100%;
    height: auto;
    flex-wrap: wrap;
    grid-row-gap: 10px;
  }
}
</style>
