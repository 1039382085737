<template>
  <div class="blog-card-blog-list" v-bind:class="rootClassName">
    <img :alt="image_alt" :src="image_src" class="blog-card-image" />
    <span class="blog-card-text">
      <span class="blog-card-text01"></span>
      <span class="blog-card-text02">Highlight May 2021</span>
    </span>
    <span class="blog-card-text03">
      <span>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        <span v-html="rawx8fb"></span>
      </span>
      <br />
      <span></span>
      <br />
      <span></span>
      <span class="blog-card-text09"></span>
      <span>
        Sed dignissim vitae sapien ut egestas. Donec diam velit. Vestibulum et
        metus sodales, maximus arcu non, tempus est.
      </span>
    </span>
    <div class="blog-card-container">
      <span class="blog-card-text11">{{ text }}</span>
      <img :alt="image_alt1" :src="image_src1" class="blog-card-image1" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlogCard',
  props: {
    text: {
      type: String,
      default: 'Read More',
    },
    image_src: {
      type: String,
      default: '/playground_assets/blog1.png',
    },
    image_alt: {
      type: String,
      default: 'image',
    },
    image_alt1: {
      type: String,
      default: 'image',
    },
    image_src1: {
      type: String,
      default: '/playground_assets/arrow-right.svg',
    },
    rootClassName: String,
  },

  data() {
    return {
      rawx8fb: ' ',
    }
  },
}
</script>

<style scoped>
.blog-card-blog-list {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  cursor:pointer;
}
.blog-card-image {
  width: 100%;
  object-fit: cover;
}
.blog-card-text {
  color: #1F252C;
  font-size: 18px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  font-family: Lora;
  font-weight: 700;
}
.blog-card-text01 {
  font-family: Lora;
}
.blog-card-text02 {
  font-family: Lora;
}
.blog-card-text03 {
  margin-top: var(--dl-space-space-halfunit);
  font-family: Montserrat;
}
.blog-card-text09 {
  color: #1F252C;
}
.blog-card-container {
  width: 100%;
  height: 37px;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: center;
}
.blog-card-text11 {
  color: #1F252C;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 700;
  letter-spacing: 0.24em;
}
.blog-card-image1 {
  object-fit: cover;
  margin-left: var(--dl-space-space-halfunit);
}

@media(max-width: 991px) {
  .blog-card-blog-list {
    width: 100%;
    flex-basis: 100%;
  }
  .rootClassName {
    flex: 1;
  }
}
</style>
