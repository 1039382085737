<template>
  <div class="footer-container max-xl:px-7 xl:px-32 overflow-hidden">
    <div class="w-full h-full max-w-rifyo mx-auto">
      <div class="grid grid-cols-12 py-8 lg:pt-20 lg:pb-10 gap-4 lg:gap-8">
        <div class="col-span-6 lg:col-span-2">
          <p class="home-footer-text-title">Rifyo</p>
          <div class="flex flex-wrap">
            <div class="w-full pb-4">
              <router-link
                style="color: inherit"
                to="/about"
                class="home-footer-text-item"
              >
                About
              </router-link>
            </div>
            <div class="w-full pb-4">
              <router-link
                style="color: inherit"
                to="/brands"
                class="home-footer-text-item"
              >
                Brands
              </router-link>
            </div>
          </div>
        </div>
        <!-- <div class="col-span-6 lg:col-span-2">
          <p class="home-footer-text-title">Customer Care</p>
          <div class="flex flex-wrap">
            <div class="w-full pb-4">
              <router-link style="color: inherit" to="/" class="home-footer-text-item">
                Technical Visit
              </router-link>
            </div>
            <div class="w-full pb-4">
              <router-link style="color: inherit" to="/" class="home-footer-text-item">
                Technical Support
              </router-link>
            </div>
            <div class="w-full pb-4">
              <router-link style="color: inherit" to="/" class="home-footer-text-item">
                User Manual and Guide
              </router-link>
            </div>
          </div>
        </div> -->
        <div class="col-span-6 lg:col-span-3">
          <p class="home-footer-text-title">FAQ</p>
          <div class="flex flex-wrap">
            <div v-for="faq in faqList" :key="faq.id" class="w-full pb-4">
              <router-link
                style="color: inherit"
                :to="`/faq?tab=${faq.id}`"
                class="home-footer-text-item"
              >
                {{ faq.category_faqs }}
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-span-12 lg:col-span-3">
          <p class="home-footer-text-title">Contact Us</p>
          <div class="flex flex-wrap">
            <div class="w-full pb-4" v-if="address.length">
              <div class="flex flex-row space-x-2 items-start">
                <img
                  alt="image"
                  src="/playground_assets/ic_phone.svg"
                  class="home-image07 w-5 h-5"
                />
                <div class="flex flex-col space-y-1">
                  <a
                    v-for="phone_number in address[0].number_phone"
                    :key="phone_number"
                    :href="'tel:' + phone_number"
                    class="home-text079 blue-academy ml-0"
                    >+{{ phone_number }}</a
                  >
                </div>
              </div>
            </div>
            <div class="w-full pb-4" v-if="address.length">
              <div class="flex flex-row space-x-2 items-start">
                <img
                  alt="image"
                  src="/playground_assets/ic_mail.svg"
                  class="home-image09 w-5 h-5"
                />
                <div class="flex flex-col space-y-1">
                  <a
                    v-for="email in address[0].email"
                    :key="email"
                    :href="'mailto:' + email"
                    class="home-text081 blue-academy ml-0"
                  >
                    <span>{{ email }}</span>
                  </a>
                </div>
              </div>
            </div>
            <div class="w-full pb-4" v-if="address.length">
              <div class="flex flex-row space-x-2 items-start">
                <img
                  alt="image"
                  src="/playground_assets/ic_wa.svg"
                  class="home-image08 w-5 h-5"
                />
                <div class="flex flex-col space-y-1">
                  <a
                    v-for="whatsapp in address[0].whatsapp"
                    :key="whatsapp"
                    :href="'https://wa.me/' + whatsapp"
                    class="home-text080 blue-academy ml-0"
                    >+{{ whatsapp }}</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-12 lg:col-span-4">
          <p class="home-footer-text-title">Visit Us</p>
          <div class="flex flex-wrap">
            <div class="w-full pb-4">
              <div class="flex flex-row space-x-2">
                <img
                  alt="image"
                  src="/playground_assets/ic_clock.svg"
                  class="home-image10 w-5 h-5"
                />
                <span class="home-text088">
                  <div class="mb-5" v-if="visitUs.moday_friday">
                    <span>Monday - Friday</span>
                    <br />
                    <span
                      >{{ visitUs.moday_friday[0].operating_hours }} WIB</span
                    >
                  </div>
                  <div v-if="visitUs.saturday">
                    <span>Saturday</span>
                    <br />
                    <span>{{ visitUs.saturday[0].operating_hours }} WIB</span>
                  </div>
                </span>
              </div>
            </div>
            <div class="w-full pb-4">
              <div class="flex flex-row space-x-2">
                <div class="w-3/4">
                  <div class="flex flex-row space-x-2" v-if="visitUs.address">
                    <img
                      alt="image"
                      src="/playground_assets/ic_map1.svg"
                      class="home-image11 w-5 h-5"
                    />
                    <a
                      :href="visitUs.address[0].url"
                      target="_blank"
                      class="home-text091"
                    >
                      <span class="text-semibold">{{
                        visitUs.address[0].building_name
                      }}</span>
                      <br />
                      <span class="pr-5 max-w-xs">{{
                        visitUs.address[0].address
                      }}</span>
                    </a>
                  </div>
                </div>
                <div class="w-1/4">
                  <img
                    alt="image"
                    src="/playground_assets/ic_barcode.svg"
                    class="home-image12"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-4 md:grid-cols-6 gap-6">
        <div class="col-span-2">
          <span class="home-footer-text-title">Payment Partners</span>
          <template v-if="payment">
            <div class="flex flex-wrap -mx-2 w-full">
              <div
                v-for="item in payment"
                :key="item.id"
                class="w-auto px-2 pt-4"
              >
                <a :href="item.link" target="_blank" rel="noopener noreferrer">
                  <img
                    alt="image"
                    :src="item.img"
                    :product_img_:src="item.img"
                    class="home-image18"
                  />
                </a>
              </div>
            </div>
          </template>
        </div>
        <div class="col-span-2">
          <span class="home-footer-text-title">Delivery Partners</span>
          <template v-if="delivery">
            <div class="flex flex-wrap -mx-2 w-full">
              <div
                v-for="item in delivery"
                :key="item.id"
                class="w-auto px-2 pt-4"
              >
                <a :href="item.link" target="_blank" rel="noopener noreferrer">
                  <img
                    alt="image"
                    :src="item.img"
                    :product_img_:src="item.img"
                    class="home-image18"
                  />
                </a>
              </div>
            </div>
          </template>
        </div>
        <div class="col-span-2">
          <span class="home-footer-text-title">Online Official Store</span>
          <template v-if="online">
            <div class="flex flex-wrap -mx-2 w-full">
              <div
                v-for="item in online"
                :key="item.id"
                class="w-auto px-2 pt-4"
              >
                <a :href="item.link" target="_blank" rel="noopener noreferrer">
                  <img
                    alt="image"
                    :src="item.img"
                    :product_img_:src="item.img"
                    class="home-image18"
                  />
                </a>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="home-footer-line"></div>
      <div class="flex flex-wrap w-full items-center py-10">
        <div class="w-full lg:w-1/4 flex flex-wrap home-container56 space-x-5">
          <template v-if="sosmed.facebook">
            <a
              :href="sosmed.facebook[0].url"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                :alt="sosmed.facebook[0].sosmed"
                src="/playground_assets/ic_facebook.svg"
                class="home-image23"
              />
            </a>
          </template>
          <template v-if="sosmed.instagram">
            <a
              :href="sosmed.instagram[0].url"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                :alt="sosmed.instagram[0].sosmed"
                src="/playground_assets/ic_instagram.svg"
                class="home-image24"
              />
            </a>
          </template>
          <template v-if="sosmed.youtube">
            <a
              :href="sosmed.youtube[0].url"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                :alt="sosmed.youtube[0].sosmed"
                src="/playground_assets/ic_youtube.svg"
                class="home-image25"
              />
            </a>
          </template>
        </div>
        <div class="w-full lg:w-2/4 text-center">
          <span class="home-text099">
            <span class="home-text100"
              >Copyright © 2021 PT Rifyo Indonesia</span
            >
          </span>
        </div>
        <div class="w-full lg:w-1/4"></div>
        <div
          class="fixed -bottom-1 left-0 right-0 z-[999] max-xl:px-5 xl:px-32 hidden md:block"
        >
          <div
            class="max-w-rifyo mx-auto flex justify-end transition hover:opacity-80 cursor-pointer"
          >
            <a
              href="https://wa.me/6287808880288"
              class="btn-contact-us inline-flex justify-center items-center py-3 px-5"
            >
              <img
                alt="image"
                src="/playground_assets/chat.svg"
                class="ic-contact-us"
              />
              <span class="text-contact-us">Contact Us</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Api from '../api';

export default {
  name: 'Footer',
  components: {},
  data() {
    return {
      footer: [],
      faqList: [],
      visitUs: [],
      sosmed: [],
      address: [],
    };
  },
  computed: {
    payment() {
      return this.footer.filter((item) => {
        return item.type === 'payment';
      });
    },
    online() {
      return this.footer.filter((item) => {
        return item.type === 'online';
      });
    },
    delivery() {
      return this.footer.filter((item) => {
        return item.type === 'delivery';
      });
    },
  },
  methods: {
    async getFooterSetting() {
      try {
        const response = await this.$http.get(`${Api.baseUrl}/homepage/footer`);
        this.footer = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getAddress() {
      try {
        const response = await this.$http.get(
          `${Api.baseUrl}/homepage/contact-us`
        );
        this.address = response.data.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getFaqCategory() {
      try {
        const response = await this.$http.get(Api.faqCategoryUrl);
        this.faqList = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getSocialMediaLink() {
      try {
        const response = await this.$http.get(Api.socialMediaLink);
        this.sosmed = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getVisitUs() {
      try {
        const response = await this.$http.get(Api.visitUs);
        this.visitUs = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.getFooterSetting();
    this.getFaqCategory();
    this.getSocialMediaLink();
    this.getVisitUs();
    this.getAddress();
  },
};
</script>
<style>
.footer-container {
  width: 100%;
  display: flex;
  overflow: auto;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  border-top: 1px solid #eae3d9;
}
.home-footer-menu {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  background-color: #fff;
  column-gap: 10px;
  justify-content: space-between;
}
.rows {
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: auto;
}
.text-semibold {
  font-weight: 600;
}
.home-footer-text-title {
  color: #828282;
  font-size: 14px;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 500;
  padding-bottom: 24px;
}
.home-footer-text-item {
  color: #1f252c !important;
  font-family: Montserrat;
  font-size: 16px;
  /* margin-bottom: var(--dl-space-space-unit); */
  margin-bottom: 12px;
}
.home-text049 {
  color: #828282;
  font-size: 14px;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text050 {
  color: #1f252c;
  font-family: Montserrat;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text051 {
  color: #1f252c;
  font-family: Montserrat;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text052 {
  color: #1f252c;
  font-family: Montserrat;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text055 {
  color: #1f252c;
  font-family: Montserrat;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text057 {
  color: #1f252c;
  font-family: Montserrat;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text059 {
  color: #1f252c;
  font-family: Montserrat;
}
.home-container27 {
  flex: 0 0 auto;
  width: 208px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text061 {
  color: #828282;
  font-size: 14px;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text062 {
  color: #1f252c;
  font-family: Montserrat;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text063 {
  color: #1f252c;
  font-family: Montserrat;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text064 {
  color: #1f252c;
  font-family: Montserrat;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text065 {
  color: #1f252c;
  font-family: Montserrat;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text067 {
  color: #1f252c;
  font-family: Montserrat;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text069 {
  color: #1f252c;
  font-family: Montserrat;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text071 {
  color: #1f252c;
  font-family: Montserrat;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text073 {
  color: #1f252c;
  font-family: Montserrat;
}
.home-container28 {
  flex: 0 0 auto;
  width: 174px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text075 {
  color: #828282;
  font-size: 14px;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text076 {
  color: #1f252c;
  font-family: Montserrat;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text077 {
  color: #1f252c;
  font-family: Montserrat;
  margin-bottom: var(--dl-space-space-unit);
}
.home-container29 {
  flex: 0 0 auto;
  width: 228px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text078 {
  color: #828282;
  font-size: 14px;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-container30 {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
}
.home-image07 {
  object-fit: cover;
}
.home-text079 {
  color: #1f252c;
  font-family: Montserrat;
  margin-left: var(--dl-space-space-halfunit);
}
.home-container31 {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
}
.home-image08 {
  object-fit: cover;
}
.home-text080 {
  color: #1f252c;
  font-family: Montserrat;
  margin-left: var(--dl-space-space-halfunit);
}
.home-container32 {
  width: 100%;
  height: var(--dl-size-size-small);
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
}
.home-image09 {
  object-fit: cover;
}
.home-text081 {
  color: #1f252c;
  font-family: Montserrat;
  line-height: 1.5;
  margin-left: var(--dl-space-space-halfunit);
}
.home-container33 {
  width: 100%;
  height: var(--dl-size-size-small);
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
}
.home-text084 {
  color: #1f252c;
  font-family: Montserrat;
  line-height: 1.5;
  margin-left: var(--dl-space-space-twounits);
}
.home-container34 {
  flex: 0 0 auto;
  width: 230px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text087 {
  color: #828282;
  font-size: 14px;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 500;
  margin-bottom: 16px;
}
.home-container35 {
  width: 100%;
  /* height: var(--dl-size-size-small); */
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-bottom: 18px;
}
.home-image10 {
  object-fit: cover;
}
.home-text088 {
  color: #1f252c;
  font-family: Montserrat;
  line-height: 1.5;
  margin-left: var(--dl-space-space-halfunit);
}
.home-container36 {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
}
.home-image11 {
  object-fit: cover;
}
.home-text091 {
  color: #1f252c !important;
  font-family: Montserrat;
  line-height: 1.5;
  margin-left: var(--dl-space-space-halfunit);
}
.home-container37 {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-image12 {
  width: 80px;
  object-fit: cover;
}
.home-footer-logo {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #fff;
}
.home-container38 {
  flex: 0 0 auto;
  width: 358px;
  height: 134px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text096 {
  color: #828282;
  font-family: Montserrat;
}
.home-container39 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.home-container40 {
  flex: 0 0 auto;
  height: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-image13 {
  object-fit: cover;
}
.home-container41 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-image14 {
  width: 100px;
  object-fit: cover;
  margin-left: var(--dl-space-space-oneandhalfunits);
  margin-right: var(--dl-space-space-oneandhalfunits);
}
.home-container42 {
  flex: 0 0 auto;
  width: 86px;
  height: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-image15 {
  width: 100px;
  object-fit: cover;
}
.home-container43 {
  flex: 0 0 auto;
  width: 400px;
  height: 134px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text097 {
  color: #828282;
  font-family: Montserrat;
}
.home-container44 {
  width: 100%;
  display: flex;
}
.home-container45 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-image16 {
  object-fit: cover;
}
.home-container46 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-image17 {
  width: 100px;
  margin-top: var(--dl-space-space-oneandhalfunits);
  object-fit: cover;
}
.home-container47,
.home-container38 {
  flex: 1;
  width: auto;
  height: auto;
  display: flex;
  object-fit: cover;
  align-items: flex-start;
  flex-direction: column;
}
.home-text098 {
  color: #828282;
  font-family: Montserrat;
}
.home-container48 {
  width: 100%;
  display: flex;
  align-items: center;
}
.home-container49 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-image18 {
  object-fit: cover;
}
.home-container50 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-image19 {
  width: 100px;
  object-fit: cover;
  margin-left: var(--dl-space-space-oneandhalfunits);
}
.home-container51 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-image20 {
  width: 100px;
  object-fit: cover;
  margin-left: var(--dl-space-space-oneandhalfunits);
}
.home-container52 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
}
.home-container53 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-image21 {
  width: 100px;
  object-fit: cover;
}
.home-container54 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-image22 {
  width: 100px;
  object-fit: contain;
  margin-left: var(--dl-space-space-oneandhalfunits);
}
.home-footer-line {
  width: 150vw;
  height: 1px;
  border-bottom: 1px solid #eae3d9;
  background-color: #fff;
  left: -50%;
  position: relative;
  padding-top: 40px;
}
.home-container55 {
  height: 10px;
  display: flex;
  align-items: flex-start;
  border-color: 1px solid #0b1118;
  border-width: 1px;
  background-color: #1f252c;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-footer {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  background-color: #fff;
}
.home-container56 {
  align-items: flex-start;
}
.home-container57 {
  width: var(--dl-size-size-small);
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}
.home-image23 {
  width: 24px;
  object-fit: cover;
}
.home-container58 {
  width: var(--dl-size-size-small);
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}
.home-image24 {
  width: 24px;
  object-fit: cover;
}
.home-container59 {
  width: var(--dl-size-size-small);
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}
.home-image25 {
  width: 24px;
  object-fit: cover;
}
.home-container60 {
  flex: 1;
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text099 {
  font-family: Montserrat;
}
.home-text100 {
  color: #1f252c;
}

.btn-contact-us {
  background-color: #1f252c;
  padding: 16px;
  align-self: flex-end;
  padding-bottom: 8px;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px -4px 16px -4px rgba(0, 0, 0, 0.24);
  border-radius: 16px 16px 0px 0px;
}
/* .ic-contact-us {
  margin-bottom: -2px;
} */
.text-contact-us {
  color: #eae3d9;
  margin-left: 14px;
  font-family: Lora;
  font-size: 18px;
  font-weight: 700;
}

/* .text-contact-us:hover {
  opacity: 0.7;
} */

.home-footer-text-item {
  cursor: pointer;
}
.home-footer-text-item:hover {
  opacity: 0.7;
  cursor: pointer;
}
.blue-academy {
  font-weight: 600;
}
.blue-academy:hover {
  opacity: 0.7;
  cursor: pointer;
}

.home-image23:hover,
.home-image24:hover,
.home-image25:hover {
  opacity: 0.7;
  cursor: pointer;
}

@media (max-width: 1024px) {
  /* mobile */
  .home-footer-menu {
    width: 100%;
    height: auto !important;
    display: flex;
    align-items: flex-start;
    padding-top: 10px;
    flex-wrap: wrap;
  }
  .home-container56 {
    justify-content: center;
    margin-bottom: 10px;
  }

  .home-image13 {
    height: 35px;
  }
  .home-footer-logo {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .home-container43 {
    height: auto;
    padding-right: 16px;
  }
  .home-container47 {
    height: auto;
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    -o-object-fit: cover;
    object-fit: cover;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .home-text098 {
    flex-basis: 100%;
  }
  .home-container48 {
    flex-basis: 100%;
    flex-direction: row;
    width: auto;
    align-items: flex-start;
    margin-top: 0px;
    column-gap: 10px;
    justify-content: space-between;
    width: 100%;
  }
  .home-container39 {
    margin-bottom: 20px;
  }

  .home-container52 {
    flex-basis: 100%;
    flex-direction: row;
    width: 100%;
    column-gap: 10px;
  }
  .home-container52 {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: flex-start;
  }
  .home-image22 {
    margin-left: 0px;
    /* margin-top: 10px; */
  }
  .home-footer {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 70px;
    flex-wrap: wrap;
  }
  .home-text100 {
    font-size: 12px;
    text-align: center;
  }
  .home-container57,
  .home-container58,
  .home-container59 {
    width: auto;
    padding-left: 16px;
    cursor: pointer;
  }
  .home-container60 {
    flex: 1;
    width: auto;
    height: 50px;
    flex-basis: 100%;
    justify-content: center;
    align-items: center;
    padding-right: 0px;
  }
  .home-image14,
  .home-image15 {
    width: auto;
    height: 35px;
  }
  .home-image16,
  .home-image17 {
    height: 30px;
    width: auto;
    margin-top: 0px;
  }
  .home-container44 {
    flex-direction: row;
    margin-bottom: 20px;
  }
  span.home-text098,
  .home-text097,
  .home-text096,
  .home-text078,
  .home-text075,
  .home-text061,
  .home-text049 {
    height: 60px;
    padding-top: 20px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
    color: #b4b4b4;
    width: 100%;
    font-weight: bold;
  }
  .home-image17 {
    margin-left: 16px;
  }
  .home-container29 {
    flex: 1;
  }
  .home-container44,
  .home-container39 {
    margin-top: 0;
  }
  .home-container40,
  .home-container42 {
    height: auto;
  }
  .home-container34 {
    display: none;
  }
  .home-container26,
  .home-container27,
  .home-container28 {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex-basis: 100%;
  }
  .rows.visit-us {
    flex-basis: 60%;
  }
  .rows.basis-50 {
    flex-basis: 45%;
  }

  .btn-contact-us {
    margin-left: auto;
    margin-right: 16px;
  }
  /* mobile */
}

@media (max-width: 900px) {
  .rows.visit-us {
    flex-basis: 70%;
  }

  .home-footer-logo {
    flex-direction: column;
  }
}

@media only screen and (min-width: 90.063em) and (max-width: 120em) {
  .home-footer-menu {
    justify-content: space-between;
  }
}
@media (min-width: 992px) and (max-width: 1024px) {
  .home-footer-menu,
  .home-footer-logo,
  .home-footer {
    padding-right: 20px;
    padding-left: 20px;
  }
  .home-container43 {
    width: 260px;
    margin-left: -3rem;
  }
}
</style>
