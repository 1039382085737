import Vue from 'vue';
import VueRouter from 'vue-router';
import VueAxios from 'vue-axios';
import VueAuthenticate from 'vue-authenticate';
import axios from 'axios';
import vueMq from 'vue-mq';
import App from './App.vue';
import store from './store';
import router from './router';
import VueLodash from 'vue-lodash';
import lodash from 'lodash';
import VueSocialSharing from 'vue-social-sharing';
import GAuth from 'vue-google-oauth2';
import axiosInterceptors from './plugins/axios';
import VueGtag from "vue-gtag";
import './plugins/utils';
import './tailwind.css';
import './style.css';

const gauthOption = {
  clientId: process.env.VUE_APP_CLIENT_ID,
  scope: 'profile email',
  prompt: 'select_account',
};
// axios refresh token
axiosInterceptors();

var filter = function(text, length, clamp){
  clamp = clamp || '...';
  var node = document.createElement('div');
  node.innerHTML = text;
  var content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
};

Vue.use(VueRouter);
Vue.filter('truncate', filter);
Vue.use(VueSocialSharing);
Vue.use(VueLodash, { lodash: lodash });
Vue.use(GAuth, gauthOption);
Vue.use(VueAxios, axios);
Vue.use(VueAuthenticate, {
  baseUrl: process.env.VUE_APP_BASE_URL,
  registerUrl: '/register',
  loginUrl: '/login',
});
Vue.use(vueMq, {
  breakpoints: {
    sm: 640,
    md: 768,
    lg: 992,
    xl: 1280,
    desktop: Infinity,
  },
});

// Configuration VueAnalytics
Vue.use(VueGtag, {
  config: { id: "UA-224118812-1" }
}, router);

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');
