<template>
  <div class="popup-header-container max-xl:px-5 xl:px-32 border-b" v-if="info[0].status === 'active'">
    <div class="popup-header-container1 flex max-w-rifyo mx-auto py-3.5">
      <span class="popup-header-text font-medium flex-1 text-center" v-html="info[0].announcement_text"></span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        @click="togglePopup()"
        class="hover:opacity-50 cursor-pointer transition ml-5 h-6 w-6 relative flex-shrink-0"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
      </svg>
      <!-- <img @click="togglePopup()" :src="image_src" :alt="image_alt" class="popup-header-image" /> -->
    </div>
  </div>
</template>

<script>
  import Api from '../api';
  export default {
    name: 'PopupHeader',
    props: {
      text: {
        type: String,
        default:
          'For more information in regard to products, pre-order, and enquiries, do not hesitate to reach our Consumer Business Whatsapp number at 0878 0888 0288',
      },
      image_src: {
        type: String,
        default: '/playground_assets/ic_close.svg',
      },
      image_alt: {
        type: String,
        default: 'image',
      },
    },
    data() {
      return {
        info: [],
      }
    },
    async created() {
      await this.getInfo();
    },
    methods: {
      togglePopup() {
        this.$store.commit('togglePopup');
      },
      async getInfo() {
        const rawResponse = await fetch(`${Api.baseUrl}/homepage/announcement`, {
          method: 'GET',
        });
        const content = await rawResponse.json();
        if (content.status) {
          this.info = content.data;
        }
      },
    },
  };
</script>

<style scoped>
  .popup-header-container {
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-direction: column;
    background: #fff;
  }
  .popup-header-container1 {
    /* flex: 0 0 auto; */
    width: 100%;
    /* height: 44px; */
    /* display: flex; */
    /* padding: var(--dl-space-space-unit); */
    align-items: center;
    /* justify-content: center; */
    /* max-width: var(--dl-size-size-maxwidth); */
  }
  .popup-header-image {
    width: 24px;
    object-fit: cover;
    margin-left: var(--dl-space-space-twounits);
  }
  .popup-header-text {
    font-size: 13px;
    font-family: Montserrat;
    /* font-weight: 500; */
    line-height: 20px;
    color: #1F252C;
    word-wrap: normal;
  }
  /* @media (max-width: 479px) {
  .popup-header-container1 {
    height: auto;
  }
} */
</style>
