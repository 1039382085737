<template>
  <div
    class="dropdown-product-promo-card cursor-pointer"
    v-bind:class="rootClassName"
  >
    <img
      :alt="product_img_alt"
      :src="product_img_src"
      class="dropdown-product-product-img"
    />
    <div class="dropdown-product-container">
      <span class="dropdown-product-text">
        <span class="dropdown-product-text1">{{ title }}</span>
      </span>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'DropdownProduct',
    props: {
      product_img_alt: {
        type: String,
        default: 'image',
      },
      title: {
        type: String,
        default: 'Chair',
      },
      rootClassName: String,
      product_img_src: {
        type: String,
        default: '/playground_assets/product1-1000w.png',
      },
    },
  };
</script>

<style scoped>
  .dropdown-product-promo-card {
    flex: 0 0 auto;
    width: 278px;
    border: 1px solid #eae3d9;
    height: 115px;
    display: flex;
    padding: var(--dl-space-space-oneandhalfunits);
    z-index: 1;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    background-color: #ffffff;
    z-index: 999;
  }
  .dropdown-product-product-img {
    height: 84px;
    width: 84px;
    object-fit: contain;
    margin-right: var(--dl-space-space-oneandhalfunits);
  }
  .dropdown-product-container {
    height: 100%;
    display: flex;
    position: relative;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .dropdown-product-text {
    color: #1f252c;
    font-size: 18px;
    font-style: normal;
    text-align: left;
    word-break: break-word;
    font-family: Lora;
    font-weight: 700;
  }
  .dropdown-product-text1 {
    font-family: lora;
  }
</style>
