const baseUrl = process.env.VUE_APP_BASE_URL;
const categoryUrl = baseUrl + '/category?page=1&field=id&order=desc';
const productUrl = baseUrl + '/product?field=id';
const productSales = baseUrl + '/sales?field=id';
const productDetailUrl = baseUrl + '/product/';
const productSalesUrl = baseUrl + '/sales/';
const cartUrl = baseUrl + '/cart';
const checkoutUrl = baseUrl + '/checkout';
const checkoutUrlPayment = baseUrl + '/checkout/payment-dp';
const areaUrl = baseUrl + '/area/city';
const shippingMethodUrl = baseUrl + '/area/cost';
const shippingMethodRepaymentUrl = baseUrl + '/area/cost-repayment';
const subdistrictUrl = baseUrl + '/area/subdistrict';
const shippingUrl = baseUrl + '/area/shipping';
const voucherUrl = baseUrl + '/voucher';
const profileUrl = baseUrl + '/profile';
const orderUrl = baseUrl + '/order/list';
const orderDetailUrl = baseUrl + '/order';
const orderDetailRepayment = baseUrl + '/checkout/repayment/list';
const orderDetailRepaymentUrl = baseUrl + '/checkout/repayment';
const inspirationUrl = baseUrl + '/inspiration';
const createPasswordUrl = baseUrl + '/create-password';
const forgotPasswordUrl = baseUrl + '/forgot';
const productRelated = baseUrl + '/homepage/product-related';
const bestSeller = baseUrl + '/homepage/best-seller';
const productPromo = baseUrl + '/homepage/promo';
const productReview = baseUrl + '/product/review';
const profileShippingUrl = baseUrl + '/shipping';
const updatePasswordUrl = baseUrl + '/profile/password';
const loginUrl = baseUrl + '/login';
const googleLoginUrl = baseUrl + '/login/google';
const facebookLoginUrl = baseUrl + '/login/facebook';
const wishlistUrl = baseUrl + '/wishlist';
const downPaymentUrl = baseUrl + '/cart/dp';
const newsLetterUrl = baseUrl + '/news-letter';
const brandsUrl = baseUrl + '/homepage/brand';
const brandsListUrl = baseUrl + '/homepage/brand/list';
const faqCategoryUrl = baseUrl + '/homepage/categoryfaq/list';
const faqUrl = baseUrl + '/homepage/faq/list';
const socialMediaLink = baseUrl + '/homepage/socialmedia';
const visitUs = baseUrl + '/homepage/visit-us';
const showCase = baseUrl + '/homepage/showcase';
const about = baseUrl + '/homepage/about';

export default {
  baseUrl,
  categoryUrl,
  productUrl,
  productDetailUrl,
  cartUrl,
  areaUrl,
  subdistrictUrl,
  shippingUrl,
  shippingMethodUrl,
  shippingMethodRepaymentUrl,
  checkoutUrl,
  checkoutUrlPayment,
  voucherUrl,
  profileUrl,
  loginUrl,
  orderUrl,
  orderDetailUrl,
  orderDetailRepayment,
  orderDetailRepaymentUrl,
  inspirationUrl,
  createPasswordUrl,
  forgotPasswordUrl,
  productRelated,
  bestSeller,
  productPromo,
  productReview,
  profileShippingUrl,
  updatePasswordUrl,
  googleLoginUrl,
  facebookLoginUrl,
  wishlistUrl,
  downPaymentUrl,
  newsLetterUrl,
  productSales,
  productSalesUrl,
  brandsUrl,
  brandsListUrl,
  faqCategoryUrl,
  faqUrl,
  socialMediaLink,
  visitUs,
  showCase,
  about,
};
