<template>
  <div class="navbar-header-wrap" v-if="hiddenNavbar">
    <popup-header v-if="isPopup"></popup-header>
    <div class="relative">
      <div
        :class="[
          'top-0 left-0 right-0 z-20',
          showNavbar ? 'fixed' : 'absolute',
        ]"
      >
        <header
          id="header"
          v-bind:class="[
            'transition-all header ',
            { inverse: showNavbar || isDropdown || isAuthPage },
          ]"
        >
          <div class="navbar-header max-w-rifyo mx-auto max-xl:px-5 ">
            <div class="navbar-left">
              <div class="default-logo mr-8 flex items-center">
                <div
                  class="justify-center items-center w-6 h-6 mr-6 -mt-1 max-lg:flex lg: hidden"
                  @click="toggleModal()"
                >
                  <IcMenu
                    :iconColor="
                      isScroll || isDropdown || isAuthPage ? '#1F252C' : '#fff'
                    "
                    class="burger-menu"
                  />
                </div>
                <router-link to="/">
                  <IcLogo
                    :iconColor="
                      isScroll || isDropdown || isAuthPage ? '#1F252C' : '#fff'
                    "
                    class="navbar-image cursor-pointer hover:opacity-80 transition"
                  />
                </router-link>
              </div>
              <div class="max-sm:hidden flex items-center h-full">
                <router-link
                  to="/inspiration"
                  class="navbar-text px-5 cursor-pointer flex justify-center items-center group"
                  v-bind:class="{ inverseText: isScroll || isDropdown }"
                >
                  <span
                    class="px-1 transition-all border-b-4 border-transparent group-hover:border-red-500 leading-none router-link-span font-medium"
                  >
                    Inspiration
                  </span>
                </router-link>
                <div
                  class="navbar-text1 group"
                  @mouseenter="shopEnter"
                  @mouseleave="shopLeave"
                  v-bind:class="{ inverseText: isScroll || isDropdown }"
                >
                  <div
                    class="cursor-pointer h-full flex justify-center items-center px-5"
                  >
                    <span
                      class="px-1 transition-all border-b-4 border-transparent group-hover:border-red-500 leading-none font-medium"
                      @click="openShopAll"
                    >
                      Shop
                    </span>
                  </div>
                  <div
                    :class="[
                      'transition-opacity overflow-hidden',
                      {
                        'opacity-0 pointer-events-none': !isDropdown,
                      },
                      {
                        'opacity-100 pointer-events-auto': isDropdown,
                      },
                    ]"
                  >
                    <navbar-dropdown></navbar-dropdown>
                  </div>
                </div>
                <div
                  @click="goTo('/sales')"
                  class="navbar-text2 px-5 flex justify-center items-center cursor-pointer hover:opacity-80 transition"
                >
                  <span class="sale">Sale</span>
                </div>
              </div>
            </div>
            <div class="navbar-right relative">
              <div class="cursor" @click="openSearch">
                <IcSearch
                  :iconColor="iSiconColor"
                  class="navbar-image1 cursor-pointer hover:opacity-60 transition"
                />
              </div>
              <div
                :class="[
                  'fixed inset-0 transition-all z-40',
                  { 'opacity-0 pointer-events-none': !showSearch },
                  { 'opacity-100 pointer-events-auto': showSearch },
                ]"
              >
                <div
                  @click="closeSearch"
                  class="bg-black bg-opacity-75 absolute inset-0 z-40"
                ></div>
                <div
                  class="bg-white shadow-lg max-xl:px-7 xl:px-32 py-6 absolute top-0 left-0 right-0 z-50"
                >
                  <form
                    @submit.prevent="searchProduct"
                    class="max-w-rifyo mx-auto flex w-full items-center border border-gray-900 py-1 relative"
                  >
                    <button
                      @click="closeSearch"
                      type="button"
                      class="lg:mx-7 max-lg:mx-3 transition hover:opacity-60 opacity-100 cursor-pointer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-7 w-7 text-black"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                    <input
                      ref="search_product"
                      v-model="searchKeyword"
                      type="text"
                      name="search"
                      placeholder="Type product name here..."
                      autocomplete="off"
                      class="text-black placeholder-gray-300 text-base max-lg:leading-7 lg:leading-10 w-full px-0 focus:outline-none focus:border-0 focus:ring-0 border-0"
                    />
                    <button
                      type="submit"
                      class="lg:mx-7 max-lg:mx-3 transition hover:opacity-60 opacity-100 cursor-pointer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-7 w-7 text-black"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        />
                      </svg>
                    </button>
                  </form>
                </div>
              </div>
              <router-link
                class="cursor"
                :to="isAuthenticated ? '/wishlist' : '/login'"
              >
                <IcHeart
                  :iconColor="iSiconColor"
                  class="navbar-image2 cursor-pointer hover:opacity-60 transition"
                />
              </router-link>
              <router-link
                :to="isAuthenticated ? '/cart' : '/login'"
                class="relative cursor cursor-pointer hover:opacity-60 transition"
              >
                <IcCart :iconColor="iSiconColor" />
                <sup
                  v-if="cartCount"
                  class="absolute text-white left-5 -top-0.5 h-4 rounded-full px-1.5 text-xs bg-red"
                  >{{ cartCount }}</sup
                >
              </router-link>
              <div
                v-if="isAuthenticated"
                class="navbar-container group"
                @click="activateMenu()"
              >
                <div class="ml-20">
                  <span
                    class="inline-block navbar-text border-b-4 border-transparent group-hover:border-red-500"
                    :class="
                      $route.fullPath == '/my-account' ? 'border-black' : ''
                    "
                    :style="`color: ${iSiconColor};`"
                    v-if="user && user.name"
                    @mouseenter="activateMenu"
                  >
                    {{ `Hi, ${user.name}` }}
                  </span>
                </div>
                <IcUser
                  :iconColor="iSiconColor"
                  class="inline-block navbar-image4 cursor-pointer hover:opacity-60 transition ml-2"
                  @mouseenter="activateMenu"
                />
              </div>
              <div
                v-else
                @click="login"
                class="navbar-container cursor-pointer hover:opacity-60 transition ml-20 font-medium font-sans"
              >
                <IcUser :iconColor="iSiconColor" class="navbar-image4" />
                <span :style="[{ color: iStextColor }]">Register/Login</span>
              </div>
              <div
                class="account-menu absolute right-0 top-3/4 border border-solid border-gray-300 bg-white flex flex-col gap-y-1 text-black"
                v-if="menu.active"
                @mouseleave="menu.active = false"
              >
                <div class="flex flex-col gap-y-2 p-4 pb-2">
                  <router-link style="color: inherit" to="/my-account">
                    My Account
                  </router-link>
                  <router-link style="color: inherit" to="/edit-info">
                    Edit Profile
                  </router-link>
                  <router-link style="color: inherit" to="/edit-password">
                    Change Password
                  </router-link>
                  <router-link style="color: inherit" to="/edit-shipping">
                    Edit Shipping Address
                  </router-link>
                </div>
                <hr />
                <button
                  type="button"
                  class="text-left px-4 py-2"
                  @click="logout"
                >
                  Logout
                </button>
              </div>
            </div>
          </div>
          <modal-menu v-if="isModal"></modal-menu>
          <modal-menu-detail v-if="isModalDetail"></modal-menu-detail>
        </header>
      </div>
    </div>
  </div>
</template>

<script>
  import NavbarDropdown from './navbar-dropdown';
  import IcCart from './icons/cart.vue';
  import IcHeart from './icons/heart.vue';
  import IcSearch from './icons/search.vue';
  import IcUser from './icons/user.vue';
  import IcLogo from './icons/logo.vue';
  import IcMenu from './icons/menu.vue';
  import ModalMenu from './modal-menu.vue';
  import ModalMenuDetail from './modal-menu-detail.vue';
  import PopupHeader from './popup-header.vue';
  export default {
    name: 'Navbar',

    components: {
      NavbarDropdown,
      IcCart,
      IcHeart,
      IcSearch,
      IcUser,
      IcLogo,
      IcMenu,
      ModalMenu,
      ModalMenuDetail,
      PopupHeader,
    },

    data() {
      return {
        isScroll: false,
        hiddenNavbar: true,
        // iSiconColor: "#fff",
        isAuthPage: false,
        showNavbar: false,
        showSearch: false,
        lastScrollPosition: 0,
        scrollValue: 0,
        headerBottom: 0,
        searchKeyword: '',
        menu: {
          active: false,
          enter: false,
        },
        screen: {
          width: screen.width,
        },
        toShopAll: false,
      };
    },

    mounted() {
    window.addEventListener('resize', () => {
      this.screen.width = screen.width;
      if (screen.width < 991) {
        this.isAuthPage = this.checkAuthPage();
      } else {
        this.isAuthPage = false;
      }
      this.checkNavbarOnMobile();
    });

    this.checkNavbarOnMobile();

    this.lastScrollPosition = window.pageYOffset;
      window.addEventListener('scroll', this.onScrollShowHeader);
      this.headerBottom =
        window.scrollY +
        document.querySelector('#header').getBoundingClientRect().bottom;
    },


    beforeDestroy() {
      window.removeEventListener('scroll', this.onScrollShowHeader);
      window.removeEventListener('resize', this.checkNavbarOnMobile);
    },

    async created() {
      const token = localStorage.getItem('token');
      if (token) {
        this.$store.commit('setUser', {
          ...JSON.parse(localStorage.getItem('user')),
          token: JSON.parse(token),
        });
        this.$store.commit('isAuthenticated', {
          isAuthenticated: true,
        });
      }
      if (this.isTransparent()) {
        window.addEventListener('scroll', this.onScroll);
      } else {
        this.isScroll = true;
      }

      if (window.location.pathname === '/') {
        this.$store.commit('showPopup');
      } else {
        this.$store.commit('hidePopup');
      }

      if (this.screen.width < 991) {
        this.isAuthPage = this.checkAuthPage();
      } else {
        this.isAuthPage = false;
      }
    },

    destroyed() {
      window.removeEventListener('scroll', this.onScroll);
    },

    watch: {
      $route(to, from) {
        if (this.isTransparent()) {
          this.isScroll = window.top.scrollY > 200;
        } else {
          this.isScroll = true;
        }

        if (window.top.scrollY > 200) {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }

        if (window.location.pathname === '/') {
          this.$store.commit('showPopup');
        } else {
          this.$store.commit('hidePopup');
        }

        if (this.isMyaccount && screen.width < 991) {
          this.hiddenNavbar = false;
        } else {
          this.hiddenNavbar = true;
        }
        if (this.screen.width < 991) {
          this.isAuthPage = this.checkAuthPage();
        } else {
          this.isAuthPage = false;
        }
      },
    },

    computed: {
      iSiconColor() {
        if (!this.isScroll && !this.isDropdown && this.$route.name == 'Home') {
          return '#FFFFFF';
        } else {
          return '#1F252C';
        }
      },
      iStextColor() {
        if (!this.isScroll && !this.isDropdown && this.$route.name == 'Home') {
          return '#FFFFFF';
        } else {
          return '#1F252C';
        }
      },
      cartCount() {
        return this.$store.state.cartCount.count;
      },
      isDropdown() {
        return this.$store.state.isDropdown;
      },
      isModal() {
        return this.$store.state.isModal;
      },
      isModalDetail() {
        return this.$store.state.isModalDetail;
      },
      isPopup() {
        return this.$store.state.isPopup;
      },
      currentRouteName() {
        return this.$route.name;
      },
      isAuthenticated() {
        return this.$store.state.isAuthenticated;
      },
      user() {
        return this.$store.state.user;
      },
      isMyaccount() {
        const arrList = [
          "EditInfo",
          "EditPassword",
          "EditShipping",
          "OrderList",
          "OrderDetail",
          "OrderReview",
        ];
        return arrList.includes(this.$route.name);
      },
    },
    methods: {
      openShopAll() {
        if (this.toShopAll) {
          this.$store.commit('setShopAllCategory', { title: null });
          this.$store.commit('setNavBarDropDown', false);
          this.$store.dispatch('hideDropdown');
          if (this.$route.params.slug !== 'all') {
            this.$router.push('/shop/all');
          }
        }
      },

      activateMenu() {
        this.menu.active = true;
        setTimeout(() => {
          document.addEventListener('click', this.deactivateMenu);
        }, 200);
      },

      deactivateMenu() {
        this.menu.active = false;
        document.removeEventListener('click', this.deactivateMenu);
      },

      logout() {
        localStorage.clear();
        this.$store.commit('clearUser');
        this.$store.commit('isAuthenticated', { isAuthenticated: false });
        this.$store.commit('setCount', 0);
        this.$router.push('/login');
      },

      async login() {
        const redirectUrl = localStorage.setItem('redirect_url', window.location.pathname);
        this.$router.push('/login');
      },

      checkNavbarOnMobile() {
        if (this.isMyaccount && screen.width < 991) {
          this.hiddenNavbar = false;
        } else {
          this.hiddenNavbar = true;
        }
      },

      onScrollShowHeader() {
        const OFFSET = 100;

        const st = window.pageYOffset || document.documentElement.scrollTop;
        if (st < this.headerBottom + OFFSET) {
          if (this.showNavbar) {
            this.showNavbar = false;
          }
        } else if (st > this.lastScrollPosition) {
          if (this.showNavbar) {
            this.showNavbar = false;
          }
          this.hide();
        } else {
          if (Math.abs(st - this.lastScrollPosition) < OFFSET) {
            return;
          }
          if (!this.showNavbar) {
            this.showNavbar = true;
          }
        }
        this.lastScrollPosition = st <= 0 ? 0 : st;
      },

      onScroll(e) {
        if (this.isTransparent()) {
          this.isScroll = window.top.scrollY > 200;
        } else {
          this.isScroll = true;
        }
      },

      isTransparent() {
        const arrList = ['/', '/login', '/register', '/otp'];
        return arrList.includes(window.location.pathname);
      },

      checkAuthPage() {
        const arrList = ['/login', '/register'];
        return arrList.includes(window.location.pathname);
      },

      toggleModal() {
        this.$store.commit('toggleModal');
      },

      toggleModalDetail() {
        this.$store.commit('toggleModalDetail');
      },

      shopEnter() {
        this.$store.commit('setNavBarShop', true);
        this.show();
        setTimeout(() => {
          this.toShopAll = true;
        }, 200);
      },

      shopLeave() {
        this.$store.commit('setNavBarShop', false);
        this.hide();
        this.toShopAll = false;
      },

      show() {
        if (!this.$store.state.isDropdown) {
          this.$store.commit('showDropdown');
        }
      },

      hide() {
        if (this.$store.state.isDropdown) {
          this.$store.commit('hideDropdown');
        }
      },

      goTo(page) {
        this.$router.replace(page);
      },

      goPage(page) {
        this.$router.push(page);
      },

      hidePopup() {
        this.$store.commit('hidePopup');
      },

      openSearch() {
        this.showSearch = true;
        this.$nextTick(() => {
          this.$refs.search_product.focus();
        });
      },

      closeSearch() {
        this.showSearch = false;
        this.searchKeyword = '';
      },

      searchProduct() {
        if (this.searchKeyword) {
          this.showSearch = false;
          this.$router.push(`/search/all?search=${this.searchKeyword}`);
        }
      },
    },

    props: {
      rootClassName: String,
    },
  };
</script>

<style scoped>
  .account-menu {
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }
  .default-logo {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .router-link-active .router-link-span {
    border-bottom: 4px solid #1f252c !important;
  }
  .active .router-link-span {
    border-bottom: 4px solid #1f252c !important;
  }

  /* .inspiration.router-link-active {
  border-bottom: 2px solid #1f252c;
} */
  .navbar-header-wrap {
    margin-bottom: -91px;
    width: 100%;
    /* height: auto; */
    /* height: 90px; */
    /* position: fixed; */
    top: 0;
    left: 0;
    z-index: 100;
  }
  .header {
    border-bottom: 1px solid transparent;
  }
  .navbar-header {
    flex: 0 0 auto;
    width: 100%;
    height: 90px;
    display: flex;
    align-items: flex-start;
    /* padding-left: var(--dl-space-space-fiveunits); */
    /* padding-right: var(--dl-space-space-fiveunits); */
    color: #fff;
  }
  .navbar-dropdown-nav-dropdown-bg {
    position: fixed;
    top: 150px;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.74);
    z-index: 10;
    overflow: hidden;
  }
  .navbar-left {
    /* flex: 0 0 auto; */
    /* width: 456px; */
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .navbar-image {
    width: 100px;
    object-fit: cover;
  }
  .navbar-text {
    height: 100%;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 500;
    color: #fff;
    text-decoration: none;
  }
  .navbar-text1 {
    height: 100%;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 500;
  }
  .navbar-text2 {
    height: 100%;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 500;
    color: #fff;
  }
  .sale {
    background-color: #ff2444;
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 5px;
  }
  .navbar-space {
    width: 390px;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .navbar-right {
    flex: 1;
    width: 200px;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
  }
  .navbar-image1 {
    width: 24px;
    height: 24px;
    object-fit: cover;
  }
  .navbar-image2 {
    width: 24px;
    height: 24px;
    object-fit: cover;
    margin-left: var(--dl-space-space-oneandhalfunits);
    margin-right: var(--dl-space-space-oneandhalfunits);
  }
  .navbar-image3 {
    width: 24px;
    height: 24px;
    object-fit: cover;
  }
  .navbar-container {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: flex-end;
    cursor: pointer;
  }

  .cursor {
    cursor: pointer;
  }
  .mr-80 {
    margin-right: 80px;
  }
  .navbar-image4 {
    width: 24px;
    height: 24px;
    object-fit: cover;
    margin-right: 8px;
  }
  .inverseText {
    /* background-color: #fff; */
    color: #1f252c;
  }
  .inverse {
    background-color: #fff;
    color: #1f252c;
    border-bottom: 1px solid #eae3d9;
  }
  .burger-menu {
    display: none;
  }

  @media (max-width: 991px) {
    .navbar-header-wrap {
      margin-bottom: -57px;
    }
    .navbar-header {
      height: 56px;
    }
    .navbar-image {
      width: 70px;
    }
    .burger-menu {
      display: block;
      /* margin-right: 16px;
    margin-top: -12px; */
    }
    /* .navbar-header {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  } */
    .navbar-left {
      width: auto;
    }
    .navbar-text {
      display: none;
    }
    .navbar-text1 {
      display: none;
    }
    .navbar-text2 {
      display: none;
    }
    .navbar-space {
      width: 100%;
    }
    .navbar-right {
      width: auto;
      justify-content: flex-end;
    }
    .navbar-container {
      display: none;
    }
    .mr-80 {
      margin-right: 0px;
    }
  }
</style>
