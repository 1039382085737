import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
const Login = () => import('../views/Login.vue');
const Register = () => import('../views/Register.vue');
const Otp = () => import('../views/Otp.vue');
const Inspiration = () => import('../views/Inspiration.vue');
const Wishlist = () => import('../views/Wishlist.vue');
const MyAccount = () => import('../views/MyAccount.vue');
const EditInfo = () => import('../views/EditInfo.vue');
const EditPassword = () => import('../views/EditPassword.vue');
const ForgotPassword = () => import('../views/ForgotPassword.vue');
const InspirationDetail = () => import('../views/InspirationDetail.vue');
const EditShipping = () => import('../views/EditShipping.vue');
const OrderList = () => import('../views/OrderList.vue');
const OrderDetail = () => import('../views/OrderDetail.vue');
const OrderReview = () => import('../views/OrderReview.vue');
const ProductReview = () => import('../views/ProductReview.vue');
const ShopAll = () => import('../views/ShopAll.vue');
const Search = () => import('../views/Search.vue');
const Cart = () => import('../views/Cart.vue');
const Checkout = () => import('../views/Checkout.vue');
const CheckoutRepayment = () => import('../views/CheckoutRepayment.vue');
const ProductDetail = () => import('../views/ProductDetail.vue');
const ProductDetailSales = () => import('../views/ProductDetailSales.vue');
// const Success = () => import('../views/Success.vue');
// const Failed = () => import('../views/Failed.vue');
// const NotFinished = () => import('../views/NotFinished.vue');
const Terms = () => import('../views/Terms.vue');
const Privacy = () => import('../views/Privacy.vue');
const Sales = () => import('../views/Sales.vue');
const About = () => import('../views/About.vue');
const Brands = () => import('../views/Brands.vue');
const BrandsDetail = () => import('../views/BrandsDetail.vue');
const FAQ = () => import('../views/FAQ.vue');
const Payment = () => import('../views/Payment.vue');

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/terms',
    name: 'TermsCondition',
    component: Terms,
  },
  {
    path: '/privacy',
    name: 'PrivacyPolicy',
    component: Privacy,
  },
  {
    path: '/otp',
    name: 'Otp',
    component: Otp,
  },
  // {
  //   path: '/success',
  //   name: 'Success',
  //   component: Success,
  // },
  // {
  //   path: '/failed',
  //   name: 'Failed',
  //   component: Failed,
  // },
  // {
  //   path: '/unsuccess',
  //   name: 'NotFinished',
  //   component: NotFinished,
  // },
  {
    path: '/inspiration',
    name: 'Inspiration',
    component: Inspiration,
  },
  {
    path: '/wishlist',
    name: 'Wishlist',
    component: Wishlist,
  },
  {
    path: '/my-account',
    name: 'MyAccount',
    component: MyAccount,
  },
  {
    path: '/edit-info',
    name: 'EditInfo',
    component: EditInfo,
  },
  {
    path: '/edit-password',
    name: 'EditPassword',
    component: EditPassword,
  },
  {
    path: '/edit-shipping',
    name: 'EditShipping',
    component: EditShipping,
  },
  {
    path: '/forgot',
    name: 'ForgotPassword',
    component: ForgotPassword,
  },
  {
    path: '/order-list',
    name: 'OrderList',
    component: OrderList,
  },
  {
    path: '/order-detail/:id',
    name: 'OrderDetail',
    component: OrderDetail,
  },
  {
    path: '/order-review/:id',
    name: 'OrderReview',
    component: OrderReview,
  },
  {
    path: '/product-review/:order_id/:id',
    name: 'ProductReview',
    component: ProductReview,
  },
  {
    path: '/sales',
    name: 'Sales',
    component: Sales,
  },
  {
    path: '/shop/:slug',
    name: 'ShopAll',
    component: ShopAll,
  },
  {
    path: '/search/:slug',
    name: 'Search',
    component: Search,
  },
  {
    path: '/product-detail/:slug',
    name: 'ProductDetail',
    component: ProductDetail,
  },
  {
    path: '/product-sales/:slug',
    name: 'ProductSales',
    component: ProductDetailSales,
  },
  {
    path: '/cart',
    name: 'cart',
    component: Cart,
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: Checkout,
  },
  {
    path: '/checkout-repayment/:id',
    name: 'CheckoutRepayment',
    component: CheckoutRepayment,
  },
  {
    path: '/inspiration-detail/:slug',
    name: 'Inspiration Detail',
    component: InspirationDetail,
  },
  {
    path: '/register-success',
    name: 'redirected',
    redirect: '/login',
  },
  {
    path: '/about',
    name: 'about',
    component: About,
  },
  {
    path: '/brands',
    name: 'Brands',
    component: Brands,
  },
  {
    path: '/brands-detail/:slug',
    name: 'Brands Detail',
    component: BrandsDetail,
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: FAQ,
  },
  {
    path: '/payment',
    name: 'Payment',
    component: Payment,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = localStorage.getItem('token');
  const forbidenPageLogged = [
    '/login',
    '/register',
    '/otp',
    '/forgot',
    'register-success',
  ];
  if (isAuthenticated) {
    if (forbidenPageLogged.includes(to.path)) {
      next({ path: '/' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
