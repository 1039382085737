<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M24 11H0V13H24V11Z" :fill="iconColor"/>
  <path d="M24 4H0V6H24V4Z" :fill="iconColor"/>
  <path d="M24 18H0V20H24V18Z" :fill="iconColor"/>
</svg>


</template>
<script>
export default {
  props: {
    iconColor: {
      type: String,
      default: "#1F252C",
    },
  },
};
</script>
<style>
</style>