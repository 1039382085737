<template>
  <div class="modal-menu-container">
    <div class="modal-menu-header-nav">
      <img
        @click="accountMenu = false"
        :alt="image_alt1"
        :src="'/playground_assets/arrow-left-black.svg'"
        class="modal-menu-image1 ml-0.5"
        v-if="accountMenu"
      />
      <img
        @click="toggleModal()"
        :alt="image_alt1"
        :src="'/playground_assets/black_close.svg'"
        class="modal-menu-image1 ml-0.5"
        v-else
      />
      <img
        :alt="image_alt"
        :src="'/playground_assets/logo.svg'"
        class="modal-menu-image ml-6 mt-1.5"
      />
    </div>
    <div class="w-full overflow-y-auto">
      <div
        class="modal-menu-title mt-5 mb-7 flex items-center"
        @click="accountMenu = !accountMenu"
        v-if="user"
      >
        <IcUser class="navbar-image4" />
        <span>
          {{ `Hi, ${user.name}` }}
        </span>
      </div>
      <div class="account-menu px-4" v-if="user && accountMenu">
        <div class="pb-8 flex flex-col gap-y-8">
          <div
            class="w-full flex justify-between"
            @click="openAccount('/my-account')"
          >
            <span>My Account</span>
            <img
              alt="arrow"
              src="/playground_assets/arrow-right-black.svg"
              class="menu-item-image"
            />
          </div>
          <div
            class="w-full flex justify-between"
            @click="openAccount('/edit-info')"
          >
            <span>Edit Profile</span>
            <img
              alt="arrow"
              src="/playground_assets/arrow-right-black.svg"
              class="menu-item-image"
            />
          </div>
          <div
            class="w-full flex justify-between"
            @click="openAccount('/edit-password')"
          >
            <span>Change Password</span>
            <img
              alt="arrow"
              src="/playground_assets/arrow-right-black.svg"
              class="menu-item-image"
            />
          </div>
          <div
            class="w-full flex justify-between"
            @click="openAccount('/edit-shipping')"
          >
            <span>Edit Shipping Address</span>
            <img
              alt="arrow"
              src="/playground_assets/arrow-right-black.svg"
              class="menu-item-image"
            />
          </div>
        </div>
        <hr />
        <div class="pt-8 w-full flex justify-between" @click="logout">
          <span>Logout</span>
          <img
            alt="arrow"
            src="/playground_assets/arrow-right-black.svg"
            class="menu-item-image"
          />
        </div>
      </div>
      <div v-if="!accountMenu">
        <p class="modal-menu-title mt-5 mb-7" @click="openInspiration">
          {{ text }}
        </p>
        <p class="modal-menu-title">{{ text2 }}</p>
        <div
          class="px-4 py-5 flex flex-col gap-y-8"
        >
          <div
            class="account-menu w-full flex justify-between"
            @click="pushCategory(category)"
            :key="category.id"
            v-for="category in filteredItems"
          >
            <span>{{ category.title }}</span>
            <img
              alt="arrow"
              src="/playground_assets/arrow-right-black.svg"
              class="menu-item-image"
            />
          </div>
        </div>
        <div class="account-menu p-4 md:pt-8" @click="openShop">
          <span>Shop All</span>
        </div>
        <div class="p-4 md:pt-8" @click="goTo('/sales')">
          <span class="sales">Sale</span>
        </div>
      </div>
    </div>
    <div class="box-login" @click="login" v-if="!user">
      <img src="/playground_assets/user_reg.svg" class="user-image" />
      <span class="text-button-reg">Register / Login</span>
    </div>
  </div>
</template>

<script>
  import MenuItem from './menu-item';
  import IcUser from './icons/user.vue';
  import Api from '../api';

  export default {
    name: 'ModalMenu',
    props: {
      text: {
        type: String,
        default: 'Inspiration',
      },
      image_alt1: {
        type: String,
        default: 'image',
      },
      text1: {
        type: String,
        default: 'Shop',
      },
      text2: {
        type: String,
        default: 'Shop',
      },
      image_alt: {
        type: String,
        default: 'image',
      },
      salesText: {
        type: String,
        default: 'Sale',
      },
    },
    components: {
      MenuItem,
      IcUser,
    },
    computed: {
      user() {
        return this.$store.state.user;
      },
      filteredItems() {
        return this.categories.filter((item) => {
          return item.status === 'active';
        });
      },
    },
    data() {
      return {
        accountMenu: false,
        categories: [],
      };
    },
    methods: {
      openInspiration() {
        this.$router.push('/inspiration');
        this.$store.commit('toggleModal');
        this.$emit('close');
      },
      openShop() {
        this.$router.push(`/shop/all`).then(() => {}).catch(() => {});
        this.$store.commit('toggleModal');
        this.$store.commit('setTitlePage', 'Shop All' );
        this.$emit('close');
      },
      pushCategory(category) {
        console.log(category);
        this.$store.commit('setNavBarDropDownMobile', category);
        this.openDetail();
      },
      async getCategory() {
        try {
          const response = await this.$http.get(Api.categoryUrl);
          this.categories = response.data.data;
        } catch (error) {
          console.log(error);
        }
      },
      openAccount(path) {
        this.toggleModal();
        this.$router.push(path);
      },
      logout() {
        this.toggleModal();
        localStorage.clear();
        this.$router.push('/login');
        window.location.reload();
      },
      login() {
        this.toggleModal();
        this.$router.push('/login');
      },
      toggleModal() {
        this.$store.commit('toggleModal');
        document.body.style.overflow = '';
        document.body.style.height = 'auto';
      },
      openDetail() {
        this.$store.commit('toggleModal');
        this.$store.commit('toggleModalDetail');
      },
      goTo(page) {
        this.$router.push(page);
        this.$store.commit('toggleModal');
        this.$emit('close');
      },
    },
    mounted() {
      this.getCategory();
    },
  };
</script>

<style scoped>
  .account-menu {
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
  .modal-menu-container {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-direction: column;
    background: white;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 999;
  }
  .box-login {
    position: absolute;
    height: 60px;
    background: #1f252c;
    bottom: 0px;
    left: 0px;
    right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .text-button-reg {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    color: #eae3d9;
    margin-left: 16px;
    text-transform: capitalize;
  }
  .modal-menu-header-nav {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid #eae3d9;
    height: 56px;
  }
  .modal-menu-image {
    height: 32px;
    object-fit: cover;
  }
  .modal-menu-image1 {
    height: 32px;
    object-fit: cover;
  }
  .modal-menu-header-menu {
    flex: 0 0 auto;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: flex-start;
  }
  .modal-menu-wrap-menu {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    grid-row-gap: 10px;
  }
  .sales {
    background: #ff2444;
    padding: 6px;
    color: #fff;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
  }
  .navbar-image4 {
    width: 24px;
    height: 24px;
    object-fit: cover;
    margin-right: 8px;
  }
  @media (max-width: 991px) {
    .modal-menu-header-nav {
      padding: var(--dl-space-space-unit);
      align-items: center;
    }
    .mt-5 {
      margin-top: 36px;
    }
    .modal-menu-image {
      width: auto;
      height: 32px;
    }
    .modal-menu-image1 {
      width: 26px;
      height: 26px;
    }
    .modal-menu-header-menu {
      padding: var(--dl-space-space-unit);
      align-items: center;
    }
    .modal-menu-text {
      color: #1f252c;
      font-size: 18px;
      font-family: Montserrat;
      margin-right: var(--dl-space-space-twounits);
      margin-top: 16px;
    }
    .modal-menu-text2 {
      color: #fff;
      padding: var(--dl-space-space-halfunit);
      font-size: 18px;
      font-family: Montserrat;
      background-color: #ff2444;
    }
    .active {
      border-bottom: 4px solid #ff2444;
    }
    .modal-menu-title {
      color: #1f252c;
      font-size: 24px;
      font-style: normal;
      font-family: Montserrat;
      font-weight: 700;
      margin-left: var(--dl-space-space-unit);
      margin-right: var(--dl-space-space-twounits);
    }
    .modal-menu-wrap-menu {
      width: 100%;
      height: 100%;
      padding: var(--dl-space-space-unit);
      padding-top: 0px;
      flex-direction: column;
    }
  }
</style>
