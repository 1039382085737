import axios from "axios";
import Api from '../../api';

const cartModule = {
  namespace: true,
  state: () => ({
    count: 0,
    carts: {
      product: [],
      product_indent: [],
      sub_total: 0,
      total: 0,
    },
  }),
  mutations: {
    setCount(state, number) {
      state.count = number;
      // localStorage.setItem('cartLength', number);
    },
    setCart(state, data) {
      state.carts = data;
    }
  },
  actions: {
    async getCart({ commit }) {
      const cart = await axios.get(Api.cartUrl);
      const cartLength = cart.data.total_qty;
      commit('setCount', cartLength);
      commit('setCart', cart.data.data);
    }
  },
};

export default cartModule;
