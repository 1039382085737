<template>
  <div class="magazine-header-magazine-header">
    <h1 class="magazine-header-magazine-headerheading">
      {{ magazineheaderheading }}
    </h1>
    <span class="magazine-header-magazine-headertext">
      {{ magazineheadertext }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'MagazineHeader',
  props: {
    'magazineheaderheading': {
      type: String,
      default: 'Love Life ',
    },
    'magazineheadertext': {
      type: String,
      default: 'Monthly highlighted issue around lifestyle.',
    },
  },
}
</script>

<style scoped>
.magazine-header-magazine-header {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
}
.magazine-header-magazine-headerheading {
  color: #1F252C;
  font-size: 3em;
  font-family: Lora;
  margin-top: var(--dl-space-space-twounits);
}
.magazine-header-magazine-headertext {
  color: #1F252C;
  font-size: 18px;
  font-style: normal;
  margin-top: var(--dl-space-space-oneandhalfunits);
  font-family: Montserrat;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}

@media(max-width: 991px) {
  .magazine-header-magazine-headertext {
  padding-left: 16px;
  }
}


@media only screen and (min-width: 90.063em) and (max-width: 120em) { 
.magazine-header-magazine-header{
  margin-top: -2px;
}
}
</style>
