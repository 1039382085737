<template>
  <div
    class="bg-white h-36 flex py-5 px-3 hover:opacity-90 transition cursor-pointer relative card-product
    "
    @click="goPage()"
    v-bind:class="rootClassName"
  >
    <img
      :alt="product_img_alt"
      :src="productimg"
      class="object-contain flex-shrink"
    />
    <div class="ml-2 flex flex-col justify-center items-start">
      <p
        class="
          text-break-word text-lg
          font-bold font-serif
          leading-snug
          mb-2
          line-clamp-3
        "
      >
        {{ title }}
      </p>
      <div
        class="flex flex-nowrap w-full whitespace-nowrap mt-auto flex-grow-0"
      >
        <span class="product-card-text3">{{ text }}</span>
        <img
          :alt="image_alt"
          :src="image_src"
          class="product-card-image h-6 ml-4"
        />
      </div>
    </div>
    <div
      v-if="product_number > 0"
      class="
        shadow-xl
        bg-gray-900
        text-white
        absolute
        -left-3
        top-1/2
        transform
        -translate-y-1/2
        transition
        z-30
        h-8
        w-8
        rounded-full
        border-solid border-4 border-white
        font-sans font-bold
        text-base
        flex
        justify-center
        items-center
      "
    >
      {{ product_number }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductCard",
  props: {
    product_number: {
      type: Number,
      default: 0,
    },
    productimg: {
      type: String,
      default:
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAMFBMVEXp7vG6vsG3u77s8fTCxsnn7O/f5OfFyczP09bM0dO8wMPk6ezY3eDd4uXR1tnJzdBvAX/cAAACVElEQVR4nO3b23KDIBRA0ShGU0n0//+2KmO94gWZ8Zxmr7fmwWEHJsJUHw8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAwO1MHHdn+L3rIoK6eshsNJ8kTaJI07fERPOO1Nc1vgQm2oiBTWJ+d8+CqV1heplLzMRNonED+4mg7L6p591FC+133/xCRNCtd3nL9BlxWP++MOaXFdEXFjZ7r8D9l45C8y6aG0cWtP/SUGhs2d8dA/ZfGgrzYX+TVqcTNRRO9l+fS5eSYzQs85psUcuzk6igcLoHPz2J8gvzWaH/JLS+95RfOD8o1p5CU5R7l5LkfKEp0mQ1UX7hsVXqDpRrifILD/3S9CfmlUQFhQfuFu0STTyJ8gsP3PH7GVxN1FC4t2sbBy4TNRTu7LyHJbqaqKFw+/Q0ncFloo7CjRPwMnCWqKXQZ75El4nKC9dmcJaou9AXOE5UXbi+RGeJygrz8Uf+GewSn9uXuplnWDZJ7d8f24F/s6iq0LYf9olbS3Q8i5oKrRu4S9ybwaQ/aCkqtP3I28QDgeoK7TBya/aXqL5COx67PTCD2grtdOwH+pQV2r0a7YVBgZoKwwIVFQYG6ikMDVRTGByopjD8ATcKb0UhhRTe77sKs2DV7FKSjId18TUEBYVyLhUThWfILHTDqmI85/2RWWjcE/bhP6OD7maT3h20MHsA47JC3PsW0wcwLhv9t0OOPOIkCn21y2bXXwlyylxiYMPk1SuCSmpfK8bNQvIrpAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADwNX4BCbAju9/X67UAAAAASUVORK5CYII=",
    },
    product_img_alt: {
      type: String,
      default: "image",
    },
    text: {
      type: String,
      default: "Detail",
    },
    slug: {
      type: String,
    },
    title: {
      type: String,
      default: "Cosm Chair, Glacier, Low Back, Fixed Arms",
    },
    image_alt: {
      type: String,
      default: "image",
    },
    rootClassName: String,
    image_src: {
      type: String,
      default: "/playground_assets/arrow-right-black.svg",
    },
  },

  data() {
    return {
      raw3qa5: " ",
    };
  },
  methods: {
    goPage() {
      if (this.slug) {
        this.$router.push(`/product-detail/${this.slug}`);
      }
    },
  },
};
</script>

<style scoped>
@media (max-width: 991px) {
  .card-product {
    min-width: 311px;
  }
}
@media (min-width: 992px) {
  .card-product {
    min-width: 320px;
  }
}

.product-card-card {
  flex: 0 0 auto;
  width: auto;
  height: 144px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  z-index: 1;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #ffffff;
}
.product-card-card:hover {
  cursor: pointer;
}
.product-card-product-img {
  width: 100px;
  object-fit: contain;
  /* margin-right: var(--dl-space-space-oneandhalfunits); */
}
.product-card-container {
  flex: 1;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  overflow-wrap: break-word;
  flex-direction: column;
  justify-content: space-around;
}
.product-card-text {
  color: #1f252c;
  font-size: 18px;
  font-style: normal;
  text-align: left;
  font-family: Lora;
  font-weight: 700;
  white-space: initial;
  width: 100%;
}
.product-card-text1 {
  font-family: lora;
  font-size: 18px;
  color: #1f252c;
}
.product-card-text2 {
  font-family: lora;
}
.product-card-container1 {
  width: 100%;
  height: 37px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  white-space: initial;
  flex-direction: row;
  justify-content: flex-start;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.24em;
  text-transform: capitalize;
  cursor: pointer;
}
.product-card-text3 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.24em;
  text-transform: capitalize;
  color: #1F252C;

}
.product-card-image {
  width: 24px;
  object-fit: cover;
  margin-left: var(--dl-space-space-halfunit);
}
.text-break-word {
  word-break: break-word;
}
</style>
