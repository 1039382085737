<template>
  <div class="home-container overflow-hidden w-screen">
    <div v-if="benners.length" class="home-section">
      <div class="relative w-full">
        <img :src="benner.photo" alt="Rifyo" class="w-full" />
        <div class="home-cover-bg max-xl:px-7 xl:px-32 absolute inset-0 z-10">
          <div class="home-container01 max-w-rifyo mx-auto relative h-full">
            <div class="home-row-hero relative flex-1 flex-grow">
              <div
                class="home-col-text w-full max-w-[500px] z-10 h-full flex flex-col justify-center mt-0 py-12"
              >
                <a
                  :href="benner.url"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div
                    class="home-text handwriting font-black text-shadow-sm leading-none max-lg:text-6xl lg:text-7xl"
                   v-html="benner.title"></div>
                </a>
                <!-- <span class="home-text003 max-w-xs text-shadow-sm">
                Sleek design with steel made, design with care and love
              </span> -->
              </div>
            </div>
            <div
              class="z-30 max-lg:h-48 lg:h-36 flex flex-wrap max-lg:flex-col lg:flex-row lg:items-center mt-4 mb-8 md:mb-16 w-full"
            >
              <h1
                class="home-text004 max-lg:text-2xl max-lg:mb-4 lg:text-4xl font-black pr-8 w-1/6 max-lg:w-full"
              >
                Related Products
              </h1>
              <div class="relative w-5/6 max-lg:w-full max-lg:h-36">
                <swiper class="swiper overflow-visible" :options="swiperOption">
                  <swiper-slide
                    v-for="pv in related_product"
                    :key="pv.id">
                    <product-card
                      :slug="pv.product.slug"
                      :productimg="pv.product.photo"
                      :title="pv.product.title"
                    ></product-card>
                  </swiper-slide>
                  <div class="swiper-button-prev hidden lg:flex" slot="button-prev"></div>
                  <div class="swiper-button-next hidden lg:flex" slot="button-next"></div>
                </swiper>
                <!-- <div
                  class="max-lg:absolute max-lg:-left-7 max-lg:top-0 max-lg:-right-7 max-lg:bottom-0"
                >
                  <div
                    v-dragscroll="true"
                    class="flex max-lg:px-7 gap-4 max-lg:overflow-x-auto overflow-y-hidden scrollbar-hide md:mr-5"
                  >
                    <product-card
                      :slug="pv.product.slug"
                      :productimg="pv.product.photo"
                      :title="pv.product.title"
                      v-for="pv in related_product"
                      :key="pv.id"
                    ></product-card>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Promo Special
      ------------------------------------------------ -->
      <div
        class="home-promo-spesial max-xl:px-7 xl:px-32 max-lg:pt-10 lg:pt-20 relative"
        :style="{
          'background-color': colorSection,
        }"
      >
        <div class="max-w-rifyo mx-auto lg:pb-32 max-lg:pb-16">
          <div class="home-container-heading">
            <h1
              class="text-custom font-bold font-serif text-center relative z-30 max-lg:mb-6 lg:mb-10"
            >
              Well-Waited, Well-Spent
            </h1>
          </div>
          <div class="relative w-full max-lg:h-80">
            <div
              class="max-lg:absolute max-lg:-left-7 max-lg:top-0 max-lg:-right-7 max-lg:bottom-0"
            >
              <div
                class="py-4 overflow-x-auto font-sans scrollbar-hide whitespace-nowrap text-center static mb-10 flex max-lg:px-7 gap-4 md:justify-center"
              >
                <div
                  class="z-50 max-lg:w-56 max-lg:flex-shrink-0 lg:flex-1 relative hover:opacity-80"
                  :key="`related-product-${index}`"
                  v-for="(item, index) in promo_spesial"
                >
                  <router-link
                    class="py-4 max-lg:px-4 lg:px-5 bg-white flex flex-col lg:flex-row lg:align-center cursor-pointer"
                    :to="`/product-detail/${item.product.slug}`"
                  >
                    <img
                      :alt="item.product.title"
                      class="lg:mr-3 mx-auto w-28 h-28"
                      :src="item.product.photo"
                    />
                    <div
                      class="flex-grow flex flex-col justify-between"
                    >
                      <div
                        class="flex-none mb-2 text-lg leading-tight font-bold text-left line-clamp-2 whitespace-normal"
                        style="font-family: Lora; height: 45px"
                      >
                        {{ item.product.title }}
                      </div>
                      <div class="w-full flex flex-wrap items-end">
                        <div class="w-full lg:w-2/12 text-left">
                          <span class="hidden lg:block w-full text-[#1f252c] font-sans">Start<br> From</span>
                          <span class="lg:hidden w-full text-[#1f252c] font-sans">Start From</span>
                        </div>
                        <div class="flex flex-wrap w-full lg:w-10/12 justify-between items-end">
                          <div class="flex flex-col">
                            <div
                              class="inline-block flex-none text-left text-sm text-gray-400 leading-tight font-normal line-through"
                            >
                              {{ `IDR ${formatRupiah(item.product.price)}` }}
                            </div>
                            <div class="flex-none flex align-center justify-between">
                              <div
                                v-if="item.product.product_variants[0][0].discount_type == 'percent'"
                                class="flex-none text-lg leading-tight font-normal"
                              >
                                {{ `IDR ${formatRupiah(item.product.price - (item.product.price * parseInt(item.product.product_variants[0][0].maximum_decimal)) / 100)}` }}
                              </div>
                              <div
                                v-else
                                class="flex-none text-lg leading-tight font-normal"
                              >
                                {{ `IDR ${formatRupiah(item.product.price - parseInt(item.product.product_variants[0][0].maximum_decimal))}` }}
                              </div>
                            </div>
                          </div>
                          <img
                            class="h-6 flex-none"
                            src="/playground_assets/arrow-right-black.svg"
                            alt="Right Arrow"
                          />
                        </div>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="home-promo-detail">
            <div class="home-wrap w-full h-full max-lg:mb-44 lg:w-1/4 flex">
              <!-- <img
                alt="image"
                src="/playground_assets/bg-yellow-1000w.png"
                class="home-image01"
              /> -->
              <h1 class="text-custom font-bold font-serif text-left relative z-30">
                Ergonomic<br />Makes Enthusiastic
                <!-- <span class="lg:font-freehand lg:font-normal lg:text-6lg"
                  >Me Time</span
                > -->
              </h1>
              <span
                class="mt-7 max-lg:text-base max-lg:mb-5 lg:text-lg font-sans lg:w-60 lg:mt-3 max-lg:mt-7 relative z-30"
              >
                Temukan kenyamanan beraktivitas di ruang kerja yang memungkinkan
                untuk mengeksplorasi ide tanpa batas.
              </span>
            </div>
            <div
              class="z-20 flex max-lg:flex-col justify-center w-full"
            >
              <img
                alt="image"
                :src="imageShowcase"
                class="opacity-0 max-lg:block lg:hidden max-lg:-mt-96"
              />
              <div
                class="max-lg:mt-10 lg:-ml-24 max-lg:absolute max-lg:-bottom-5-mt-96 max-lg:-left-5 max-lg:-right-16 max-lg:z-20 relative overflow-hidden"
              >
                <img
                  alt="image"
                  :src="imageShowcase"
                  class="w-full max-lg:hidden lg:block"
                />
                <img
                  alt="image"
                  :src="imageShowcase"
                  class="w-full max-lg:block lg:hidden"
                />
                <template v-for="si in show_case">
                  <button
                    :key="si.id"
                    type="button"
                    @click="setPinActive(si.pointer)"
                    :class="[
                      'absolute transition opacity-100 hover:opacity-80 cursor-pointer z-50 h-8 w-8 rounded-full btn-weekendmetime-01 border-solid border-4 border-white font-sans font-bold text-base flex justify-center items-center',
                      { 'bg-white text-gray-900': !pin_active.includes(si.pointer) },
                      { 'bg-gray-900 text-white': pin_active.includes(si.pointer) },
                    ]"
                    :style="{
                      'top': si.top,
                      'left': si.left,
                    }"
                  >
                    {{ si.pointer }}
                  </button>
                </template>
              </div>
            </div>
            <div class="w-1/4">
                <div class="relative z-40 pl-3 max-lg:hidden lg:block">
                  <div
                    class="lg:-ml-12 w-full lg:w-[250px]"
                  >
                    <template v-for="wmtp in show_case">
                      <product-card-new
                        :key="wmtp.id"
                        :slug="wmtp.slug"
                        :productimg="wmtp.photo.photo[0]"
                        :title="wmtp.title"
                        :product_number="wmtp.pointer"
                        class="product-showcase"
                        :class="{
                          'pin-active-new': pin_active.includes(wmtp.pointer)
                        }"
                      ></product-card-new>
                    </template>
                  </div>
                </div>
            </div>
            <div
              class="relative max-lg:mt-52 z-40 pl-3 max-lg:block lg:hidden w-full"
            >
              <div class="z-40 grid grid-cols-1">
                <template v-for="wmtp in show_case">
                  <product-card
                    :key="wmtp.id"
                    :slug="wmtp.slug"
                    :productimg="wmtp.photo.photo[0]"
                    :title="wmtp.title"
                    :product_number="wmtp.pointer"
                    class="product-showcase"
                    :class="{
                      'pin-active': pin_active.includes(wmtp.pointer)
                    }"
                  ></product-card>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="home-promo-spesial max-lg:px-7 xl:px-32 max-lg:pt-0 lg:pt-0 relative"
        :style="{
          'background-color': colorSection,
        }"
      >
        <div class="max-w-rifyo mx-auto">
          <div class="home-container-heading1">
            <h1 class="text-custom font-bold font-serif text-center max-lg:mb-6 lg:mb-10">
              Well-Liked, Well-Sold
            </h1>
          </div>
          <div class="home-best-seller-wrap">
            <div class="home-best-seller">
              <best-card
                :slug="pv.slug"
                :photo="pv.photo"
                :title="pv.title"
                v-for="pv in best_seller"
                :key="pv.id"
              ></best-card>
            </div>
          </div>
        </div>
      </div>
      <div class="relative w-full" v-if="bennerPromoAtas.status === 'active'">
        <a
          :href="bennerPromoAtas.url"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            :src="bennerPromoAtas.image"
            alt="dekstop-banner-aeron"
            width="100%"
            height="auto"
          />
        </a>
      </div>
      <div class="relative w-full" v-if="bennerPromoBawah.status === 'active'">
        <a
          :href="bennerPromoBawah.url"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            :src="bennerPromoBawah.image"
            alt="dekstop-banner-cristmas"
            width="100%"
            height="auto"
          />
        </a>
      </div>
      <!-- <div class="home-subsribe max-xl:px-7 xl:px-32">
        <div
          class="w-full h-full max-w-rifyo mx-auto flex flex-col md:flex-row md:flex-0 items-center"
        >
          <div class="home-container23 py-8">
            <h1 class="home-text042 font-black">
              <span>Subscribe our magazine</span>
            </h1>
          </div>
          <div class="home-container24 py-4 md:py-8">
            <input
              type="text"
              class="home-text046 bg-transparent border-transparent outline-none shadow-none"
              placeholder="Enter your email"
              v-model="email"
            />
          </div>
          <div
            class="home-container25 flex w-full max-lg:justify-end py-8 md:pl-12"
            @click="subscribe"
          >
            <span class="home-text047 text-base font-bold">Subscribe</span>
            <img
              alt="image"
              src="/playground_assets/arrow-right-beige.svg"
              class="home-image06"
            />
          </div>
        </div>
      </div> -->
    </div>
    <Footer />
  </div>
</template>

<script>
  import ProductCard from '../components/product-card';
  import ProductCardNew from '../components/product-card-new';
  import ProductCardWeekly from '../components/product-card-weekly';
  import SpacerW16 from '../components/spacer-w16';
  import PromotionCard from '../components/promotion-card';
  import SpacerH16 from '../components/spacer-h16';
  import BestCard from '../components/best-card';
  import BlogCard from '../components/blog-card';
  import Footer from '../components/footer';
  import Interior from '../components/interior';
  import MagazineHeader from '../components/home-section/magazine-header.vue';
  import Magazine from '../components/home-section/magazine.vue';
  import Api from '../api';
  import { formatRupiah } from '../lib';
  import { dragscroll } from 'vue-dragscroll';
  import axios from 'axios';
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
  import 'swiper/css/swiper.css';
  export default {
    name: 'Home',
    components: {
      ProductCard,
      ProductCardNew,
      ProductCardWeekly,
      SpacerW16,
      PromotionCard,
      SpacerH16,
      BestCard,
      BlogCard,
      Footer,
      MagazineHeader,
      Magazine,
      Interior,
      Swiper,
      SwiperSlide
    },
    metaInfo: {
      title: 'Rifyo',
      meta: [
        {
          property: 'og:title',
          content: 'Rifyo',
        },
      ],
    },
    directives: {
      dragscroll,
    },
    data() {
      return {
        benners:[],
        bennersPromo:[],
        email: null,
        img1: false,
        img2: false,
        img3: false,
        related_product: [],
        wmt_products: [
          {
            product: {
              slug: 'aeron-chair-graphite',
              photo:
                'https://cms.rifyo.saga.id//storage/photos/2/aeron_chair_graphite_size_A_1.jpg',
              title: 'Aeron Chair Graphite',
            },
            id: 1,
          },
          {
            product: {
              slug: 'oripura',
              photo:
                'https://cms.rifyo.saga.id//storage/photos/2/oripura_thumbnail.jpg',
              title: 'Oripura',
            },
            id: 2,
          },
          {
            product: {
              slug: 'lolly-personal-light',
              photo:
                'https://cms.rifyo.saga.id//storage/photos/2/lolly_personal_light_black_1.jpg',
              title: 'Lolly Personal Light',
            },
            id: 3,
          },
        ],
        swiperOption: {
          slidesPerView: 3,
          spaceBetween: 16,
          pagination: {
            el: '.swiper-pagination',
            type: 'fraction'
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          },
          breakpoints: {
            1440: {
              slidesPerView: 3,
              spaceBetween: 16
            },
            1024: {
              slidesPerView: 2,
              spaceBetween: 16
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 16
            },
            320: {
              slidesPerView: 1,
              spaceBetween: 16,
            }
          }
        },
        promo_spesial: [],
        best_seller: [],
        show_case: [],
        imageShowcase: null,
        colorSection: null,
        pin_active: [],
        photo_default:
          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAMFBMVEXp7vG6vsG3u77s8fTCxsnn7O/f5OfFyczP09bM0dO8wMPk6ezY3eDd4uXR1tnJzdBvAX/cAAACVElEQVR4nO3b23KDIBRA0ShGU0n0//+2KmO94gWZ8Zxmr7fmwWEHJsJUHw8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAwO1MHHdn+L3rIoK6eshsNJ8kTaJI07fERPOO1Nc1vgQm2oiBTWJ+d8+CqV1heplLzMRNonED+4mg7L6p591FC+133/xCRNCtd3nL9BlxWP++MOaXFdEXFjZ7r8D9l45C8y6aG0cWtP/SUGhs2d8dA/ZfGgrzYX+TVqcTNRRO9l+fS5eSYzQs85psUcuzk6igcLoHPz2J8gvzWaH/JLS+95RfOD8o1p5CU5R7l5LkfKEp0mQ1UX7hsVXqDpRrifILD/3S9CfmlUQFhQfuFu0STTyJ8gsP3PH7GVxN1FC4t2sbBy4TNRTu7LyHJbqaqKFw+/Q0ncFloo7CjRPwMnCWqKXQZ75El4nKC9dmcJaou9AXOE5UXbi+RGeJygrz8Uf+GewSn9uXuplnWDZJ7d8f24F/s6iq0LYf9olbS3Q8i5oKrRu4S9ybwaQ/aCkqtP3I28QDgeoK7TBya/aXqL5COx67PTCD2grtdOwH+pQV2r0a7YVBgZoKwwIVFQYG6ikMDVRTGByopjD8ATcKb0UhhRTe77sKs2DV7FKSjId18TUEBYVyLhUThWfILHTDqmI85/2RWWjcE/bhP6OD7maT3h20MHsA47JC3PsW0wcwLhv9t0OOPOIkCn21y2bXXwlyylxiYMPk1SuCSmpfK8bNQvIrpAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADwNX4BCbAju9/X67UAAAAASUVORK5CYII=',
      };
    },
    computed: {
      isDesktop() {
        return this.$mq === 'lg' || this.$mq === 'xl' || this.$mq === 'desktop';
      },
      benner() {
        if (this.isDesktop) {
          return this.benners.filter((benner) => benner.type == 'web')[0];
        } else {
          return this.benners.filter((benner) => benner.type == 'mobile')[0];
        }
      },
      bennerPromoAtas() {
        if (this.isDesktop) {
          return this.bennersPromo.atas.filter((benner) => benner.type == 'web')[0];
        } else {
          return this.bennersPromo.atas.filter((benner) => benner.type == 'mobile')[0];
        }
      },
      bennerPromoBawah() {
        if (this.isDesktop) {
          return this.bennersPromo.bawah.filter((benner) => benner.type == 'web')[0];
        } else {
          return this.bennersPromo.bawah.filter((benner) => benner.type == 'mobile')[0];
        }
      },
      isAuthenticated() {
        return this.$store.state.isAuthenticated;
      },
    },
    async created() {
      await this.getBenner();
      await this.getBennerPromo();
      await this.getListRelatedProduct();
      await this.getListBestSeller();
      await this.getListPromoSpesial();
      await this.getShowCase();
      // if (this.isAuthenticated) {
      //   await this.getCart();
      // }
      console.log(this.$mq);
    },
    methods: {
      async getShowCase() {
        try {
          const { data } = await axios.get(`${Api.showCase}`);
          if (data.status) {
            this.show_case = data.data.showcase;
            this.imageShowcase = data.data.showcase_background;
            this.colorSection = data.data.color;
          }
        } catch (error) {
          throw new Error(error);
        }
      },
      async getBenner() {
        const rawResponse = await fetch(`${Api.baseUrl}/homepage/banner`, {
          method: 'GET',
        });
        const content = await rawResponse.json();
        if (content.status) {
          this.benners = content.data;
        }
      },
      async getBennerPromo() {
        const rawResponse = await fetch(`${Api.baseUrl}/homepage/bannerpromo`, {
          method: 'GET',
        });
        const content = await rawResponse.json();
        if (content.status) {
          this.bennersPromo = content.data;
        }
      },
      formatRupiah(value) {
        return formatRupiah(value);
      },
      async subscribe() {
        try {
          const rawResponse = await fetch(`${Api.newsLetterUrl}`, {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              email: this.email,
            }),
          });
          const content = await rawResponse.json();
          alert(content.message);
        } catch (error) {
          alert(error.message);
        }
      },
      async getListRelatedProduct() {
        try {
          const response = await this.$http.get(Api.productRelated);
          this.related_product = response.data.data.map((f) => {
            return {
              ...f,
              product: {
                ...f.product,
                title: f.product ? f.product.title : '',
                photo: f.product.photo ? f.product.photo : this.photo_default,
              },
            };
          });
        } catch (error) {
          console.log(error);
          this.msg_err = error.message;
          this.isError = true;
        }
      },
      async getListBestSeller() {
        try {
          const response = await this.$http.get(Api.bestSeller);
          this.best_seller = response.data.data.map((f) => {
            return {
              ...f,
              photo: f.photo ? f.photo : this.photo_default,
            };
          });
        } catch (error) {
          console.log(error);
          this.msg_err = error.message;
          this.isError = true;
        }
      },
      async getListPromoSpesial() {
        try {
          const response = await this.$http.get(Api.productPromo);
          this.promo_spesial = response.data.data.map((f) => {
            return {
              ...f,
              product: {
                ...f.product,
                title: f.product ? f.product.title : '',
                photo: f.product.photo
                  ? f.product.photo
                  : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAMFBMVEXp7vG6vsG3u77s8fTCxsnn7O/f5OfFyczP09bM0dO8wMPk6ezY3eDd4uXR1tnJzdBvAX/cAAACVElEQVR4nO3b23KDIBRA0ShGU0n0//+2KmO94gWZ8Zxmr7fmwWEHJsJUHw8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAwO1MHHdn+L3rIoK6eshsNJ8kTaJI07fERPOO1Nc1vgQm2oiBTWJ+d8+CqV1heplLzMRNonED+4mg7L6p591FC+133/xCRNCtd3nL9BlxWP++MOaXFdEXFjZ7r8D9l45C8y6aG0cWtP/SUGhs2d8dA/ZfGgrzYX+TVqcTNRRO9l+fS5eSYzQs85psUcuzk6igcLoHPz2J8gvzWaH/JLS+95RfOD8o1p5CU5R7l5LkfKEp0mQ1UX7hsVXqDpRrifILD/3S9CfmlUQFhQfuFu0STTyJ8gsP3PH7GVxN1FC4t2sbBy4TNRTu7LyHJbqaqKFw+/Q0ncFloo7CjRPwMnCWqKXQZ75El4nKC9dmcJaou9AXOE5UXbi+RGeJygrz8Uf+GewSn9uXuplnWDZJ7d8f24F/s6iq0LYf9olbS3Q8i5oKrRu4S9ybwaQ/aCkqtP3I28QDgeoK7TBya/aXqL5COx67PTCD2grtdOwH+pQV2r0a7YVBgZoKwwIVFQYG6ikMDVRTGByopjD8ATcKb0UhhRTe77sKs2DV7FKSjId18TUEBYVyLhUThWfILHTDqmI85/2RWWjcE/bhP6OD7maT3h20MHsA47JC3PsW0wcwLhv9t0OOPOIkCn21y2bXXwlyylxiYMPk1SuCSmpfK8bNQvIrpAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADwNX4BCbAju9/X67UAAAAASUVORK5CYII=',
              },
            };
          });
        } catch (error) {
          console.log(error);
          this.msg_err = error.message;
          this.isError = true;
        }
      },
      // async getCart() {
      //   try {
      //     const cart = await axios.get(Api.cartUrl);
      //     const cartLength =
      //       cart.data.data.product.length +
      //       cart.data.data.product_indent.length;
      //     this.$store.commit('setCount', cartLength);
      //   } catch (error) {
      //     throw error;
      //   }
      // },
      openProduct(id) {
        if (id === 1) {
          this.img1 = true;
        } else if (id === 2) {
          this.img2 = true;
        } else if (id === 3) {
          this.img3 = true;
        }
      },
      setPinActive(id) {
        const index = this.pin_active.indexOf(id);
        if (index > -1) {
          this.pin_active.splice(index, 1);
        } else {
          this.pin_active = [];
          this.pin_active.push(id);
        }
      },
    },
  };
</script>

<style scoped>
  .swiper-slide {
    width: 311px;
    transition: 0.5s;
  }
  .swiper-button-prev, .swiper-button-next {
    width: 52px;
    height: 52px;
    background: #fff;
    border: 4px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.59);
    border-radius: 9999px;
  }
  .swiper-button-next, .swiper-rtl .swiper-button-prev {
    right: -35px;
  }
  .swiper-button-prev, .swiper-rtl .swiper-button-next {
    left: -25px;
  }
  .swiper-button-prev:after, .swiper-button-next:after {
    content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI0IDEyLjAwMDJINSIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik0xMyAyMS4wMDAyTDYuMTIxMzIgMTQuMTIxNkM0Ljk0OTc0IDEyLjk1IDQuOTQ5NzUgMTEuMDUwNSA2LjEyMTMyIDkuODc4OTJMMTMgMy4wMDAyNCIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
    line-height: 0;
  }
  .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
    content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzI5MTRfMjgxOTYpIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xOSAxMy4wMDAyTDAgMTMuMDAwMkwwIDExLjAwMDJMMTkgMTEuMDAwMlYxMy4wMDAyWiIgZmlsbD0iIzFGMjUyQyIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTE2Ljc1NzQgOS4xNzE5TDExLjI5MyAzLjcwNzQzTDEyLjcwNzIgMi4yOTMyMUwxOC4xNzE3IDcuNzU3NjhDMjAuNTE0OCAxMC4xMDA4IDIwLjUxNDggMTMuODk5OCAxOC4xNzE2IDE2LjI0M0wxMi43MDcyIDIxLjcwNzRMMTEuMjkzIDIwLjI5MzJMMTYuNzU3NCAxNC44Mjg3QzE4LjMxOTUgMTMuMjY2NiAxOC4zMTk1IDEwLjczNCAxNi43NTc0IDkuMTcxOVoiIGZpbGw9IiMxRjI1MkMiLz4KPC9nPgo8ZGVmcz4KPGNsaXBQYXRoIGlkPSJjbGlwMF8yOTE0XzI4MTk2Ij4KPHJlY3Qgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0IiBmaWxsPSJ3aGl0ZSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAwLjAwMDI0NDE0MSkiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4K);
    line-height: 0;
  }
  .swiper-slide-next ~ .swiper-slide ~ .swiper-slide {
    opacity: 0.5;
  }
  .swiper-slide-prev {
    opacity: 0;
  }
  .swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
    opacity: 0;
    cursor: auto;
    pointer-events: none;
  }
  .text-custom {
    font-size: 48px;
    line-height: 64px;
  }
  .btn-weekendmetime-01 {
    right: 35%;
    bottom: 41%;
  }
  .btn-weekendmetime-02 {
    right: 60%;
    bottom: 62%;
  }
  .btn-weekendmetime-03 {
    right: 67%;
    bottom: 76%;
  }
  .bg-orange {
    /* width: 630px; */
    /* height: 870px; */
    background: #edb61b;
    border-radius: 0px 0px 160px 0px;
  }
  .text-group-mobile {
    display: none;
  }
  .text-metime {
    display: none;
  }
  .home-related-product {
    display: none;
  }
  span.home-promo-note {
    display: none;
  }
  .home-container {
    width: 100%;
    display: flex;
    overflow: auto;
    /* min-height: 100vh; */
    align-items: center;
    flex-direction: column;
    background-color: #1f252c;
  }
  .home-section {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-cover-bg {
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    padding-top: 0px;
    /* padding-left: 80px; */
    /* padding-right: 80px; */
    flex-direction: column;
    /* padding-bottom: 120px; */
    justify-content: flex-start;
    /* background-color: #6b89e8; */
    padding-top: 100px;
  }
  .home-container01 {
    width: 100%;
    /* height: 625px; */
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-direction: column;
    /* padding-bottom: var(--dl-space-space-threeunits); */
    justify-content: flex-start;
  }
  .home-row-hero {
    width: 100%;
    /* min-height: 480px; */
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
  }
  .product-card-wrap {
    width: 30%;
  }
  .home-col-text {
    width: 332px;
    display: flex;
    align-self: center;
    margin-top: var(--dl-space-space-oneandhalfunits);
    align-items: flex-start;
    flex-direction: column;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .home-text {
    color: #eae3d9;
    font-size: 5em;
    font-family: Lora;
    /* line-height: 82px; */
  }
  .home-container02 {
    flex: 0 0 auto;
    width: auto;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .home-text001 {
    color: #eae3d9;
    font-size: 5em;
    font-family: Lora;
    margin-top: -32px;
  }
  .home-text002 {
    color: #eae3d9;
    z-index: 100;
    font-size: 5em;
    font-family: freehand;
    margin-left: 20px;
    z-index: 1;
    font-weight: 400;
    margin-top: -14px;
  }
  .home-text003 {
    color: #eae3d9;
    font-size: 18px;
    margin-top: var(--dl-space-space-unit);
    font-family: Montserrat;
  }
  .home-col-image {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .home-image {
    width: 100%;
    margin-top: -300px;
    object-fit: cover;
  }
  .home-row-card {
    width: 100%;
    /* height: 100px; */
    display: flex;
    position: relative;
    /* margin-top: var(--dl-space-space-sixunits); */
    align-items: center;
    /* margin-bottom: var(--dl-space-space-fourunits); */
    flex-direction: row;
    justify-content: space-around;
  }
  .home-card-transparent {
    width: 238px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .home-text004 {
    color: #eae3d9;
    font-family: Lora;
  }
  .home-promo-spesial {
    background-color: #e6ebcb;
    width: 100%;
  }
  .home-container-heading {
    width: 100%;
    /* height: 174px; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #eae3d9; */
  }
  .home-heading {
    color: #1f252c;
    font-size: 3em;
    font-family: lora;
  }
  .home-text007 {
    color: #1f252c;
    font-size: 3em;
    font-family: freehand;
    margin-left: var(--dl-space-space-oneandhalfunits);
  }
  .home-promo-list {
    width: 100%;
    height: 152px;
    padding-left: 80px;
    padding-right: 80px;
    justify-content: flex-start;
    background-color: #eae3d9;
  }
  .home-container03 {
    flex: 0 1 33%;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    background-color: #eae3d9;
  }
  .home-promo-detail {
    width: 100%;
    /* height: 672px; */
    display: flex;
    /* margin-top: -55px; */
    align-items: center;
  }
  .home-wrap {
    /* flex: 1; */
    /* height: 100%; */
    display: flex;
    position: relative;
    align-items: flex-start;
    /* padding-left: 80px; */
    flex-direction: column;
    /* padding-bottom: var(--dl-space-space-sixunits); */
    justify-content: center;
    /* background-color: #eae3d9; */
  }
  .home-image01 {
    top: 0;
    left: 0;
    height: 600px;
    position: absolute;
    object-fit: cover;
  }
  .home-promo-title {
    color: #1f252c;
    z-index: 1;
    font-size: 36px;
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
  }
  .home-promo-subtitle {
    z-index: 1;
    font-size: 3em;
    font-family: freehand;
    color: #1f252c;
  }
  .home-promo-desc {
    color: #1f252c;
    z-index: 1;
    max-width: var(--dl-size-size-xxlarge);
    font-style: normal;
    margin-top: var(--dl-space-space-halfunit);
    font-family: Montserrat;
    font-weight: 400;
    padding-left: 0px;
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-wrap1 {
    flex: 1;
    height: 100%;
    display: flex;
    position: relative;
    align-self: center;
    align-items: flex-start;
    padding-right: var(--dl-space-space-fiveunits);
    flex-direction: column;
    justify-content: center;
    /* background-color: #eae3d9; */
  }

  .home-product-group {
    right: 80px;
    display: flex;
    z-index: 1;
    position: absolute;
    flex-direction: column;
    gap: 20px;
    max-width: 360px;
    min-height: 500px;
  }
  .home-container-heading1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #eae3d9; */
  }
  .home-text010 {
    color: #1f252c;
    font-size: 3em;
    font-family: lora;
  }
  .home-text011 {
    color: #524538;
    font-size: 3em;
    font-family: freehand;
    margin-left: var(--dl-space-space-oneandhalfunits);
  }
  .home-best-seller-wrap {
    position: relative;
    width: 100%;
    height: auto;
  }
  .home-best-seller {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-bottom: var(--dl-space-space-fiveunits);
    justify-content: center;
    /* background-color: #eae3d9; */
    row-gap: 20px;
    column-gap: 20px;
    height: 537px;
  }
  .home-banner-promo {
    flex: 0 0 auto;
    width: 100%;
    /* height: 288px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #1f252c;
  }
  .home-promo-img {
    flex: 0 0 auto;
    height: 100%;
    display: flex;
    align-self: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .home-image03 {
    height: 80px;
    object-fit: cover;
  }
  .home-promo-title1 {
    flex: 0 0 auto;
    width: auto;
    height: auto;
    display: flex;
    align-items: flex-start;
    margin-left: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: space-between;
  }
  .home-text012 {
    color: #fff;
    width: 100%;
    font-size: 3em;
    font-family: Lora;
  }
  .home-container04 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-end;
  }
  .home-text014 {
    color: #eae3d9;
    font-size: 3em;
    font-family: Lora;
    font-weight: 700;
    /* margin-bottom: var(--dl-space-space-oneandhalfunits); */
  }
  .text-group-web,
  .text-group-mobile {
    color: #eae3d9;
  }
  .home-text015 {
    color: #ff2444;
    font-size: 3em;
    font-family: Lora;
    font-weight: 700;
    margin-left: var(--dl-space-space-unit);
  }
  .home-promo-text {
    /* flex: 0 0 auto; */
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .home-text016 {
    color: #eae3d9;
    font-family: Montserrat;
  }
  .home-red-box {
    position: absolute;
    right: 0;
    width: 144px;
    height: 100px;
    background-color: #ff2444;
  }
  .home-banner-hero {
    width: 100%;
    height: 222px;
    display: flex;
    align-items: flex-end;
    padding-left: calc(calc(100% - 996px) / 2);
    padding-right: calc(calc(100% - 996px) / 2);
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: space-between;
    background-color: #eae3d9;
  }
  .home-container05 {
    flex: 0 0 auto;
    width: 378px;
    height: 125px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 65px;
  }
  .home-text017 {
    color: var(--black-logo);
    font-size: 3em;
    font-family: Lora;
    line-height: 64px;
  }
  .home-container06 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
  }
  .home-text018 {
    color: var(--black-logo);
    font-size: 3em;
    font-family: Lora;
  }
  .home-text019 {
    color: #524538;
    font-size: 3em;
    font-family: freehand;
    margin-left: var(--dl-space-space-unit);
  }
  .home-container07 {
    flex: 0 0 auto;
    width: 572px;
    height: 125px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }
  .home-text020 {
    color: #1f252c;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 400;
    line-height: 1.4;
    text-align: left;
  }
  .home-banner-gallery {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-color: #eae3d9;
    margin-top: -2px;
    padding-bottom: 64px;
  }
  .text-shadow {
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.4);
  }
  .home-gallery-img {
    flex: 0 0 auto;
    width: 376px;
    height: 371px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background-size: cover;
    background-image: url('/playground_assets/maintenance_care.png');
  }

  .home-gallery-img-customer {
    flex: 0 0 auto;
    width: 620px;
    height: 371px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background-size: cover;
    background-image: url('/playground_assets/customer_care.png');
    margin-right: 16px;
  }
  .home-title-gallery {
    color: #ffffff;
    font-size: 30px;
    align-self: flex-start;
    text-align: left;
    font-family: Lora;
    font-weight: 700;
    padding: 36px;
  }
  .badge-customer {
    display: flex;
    height: 64px;
    width: 306px;
    background-color: #fff;
    margin-left: 36px;
    margin-bottom: 16px;
  }

  .badge-customer:nth-child(3) {
    margin-left: 100px;
  }

  .badge-customer:nth-child(4) {
    margin-left: 164px;
  }

  .badge-customer span {
    font-family: Lora;
    font-weight: 700;
    color: #1f252c;
    align-self: center;
    padding-left: 16px;
  }

  .badge-customer-img {
    height: 64px;
    width: 64px;
  }

  .home-gallery-img1 {
    flex: 0 0 auto;
    width: 278px;
    height: 278px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background-size: cover;
    justify-content: center;
    background-image: url('/playground_assets/maintenance-1000h.png');
  }
  .home-title1 {
    color: #ffffff;
    font-size: 24px;
    align-self: center;
    text-align: center;
    font-family: Lora;
  }
  .home-gallery-img2 {
    flex: 0 0 auto;
    width: 278px;
    height: 278px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background-size: cover;
    justify-content: center;
    background-image: url('/playground_assets/technical-visit-1000h.png');
  }
  .home-title2 {
    color: #ffffff;
    font-size: 24px;
    align-self: center;
    text-align: center;
    font-family: Lora;
  }
  .home-gallery-img3 {
    flex: 0 0 auto;
    width: 278px;
    height: 278px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background-size: cover;
    justify-content: center;
    background-image: url('/playground_assets/support-1000h.png');
  }
  .home-title3 {
    color: #ffffff;
    font-size: 24px;
    align-self: center;
    text-align: center;
    font-family: Lora;
  }
  .home-magazine {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-bottom: var(--dl-space-space-fourunits);
    background-color: #fff;
  }
  .home-container08 {
    flex: 0 0 auto;
    width: 300px;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .home-container09 {
    flex: 0 0 auto;
    width: 226px;
    height: 320px;
    display: flex;
    margin-top: var(--dl-space-space-twounits);
    align-items: flex-start;
    flex-direction: column;
    background-size: cover;
    background-image: url('/playground_assets/cover-1000w.png');
  }
  .home-container10 {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
    background-color: #fff;
  }
  .home-container11 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .home-container12 {
    flex: 0 0 auto;
    width: 336px;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .home-text029 {
    color: #524538;
    font-family: Lora;
  }
  .home-text030 {
    color: #524538;
    font-size: 2em;
    font-family: freehand;
    margin-left: var(--dl-space-space-oneandhalfunits);
  }
  .home-container13 {
    flex: 0 0 auto;
    width: 257px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
  .home-text031 {
    font-style: normal;
    font-family: Montserrat;
    font-weight: 500;
    margin-right: 8px;
    letter-spacing: 0.24em;
  }
  .home-image04 {
    width: 24px;
    object-fit: cover;
  }
  .home-container14 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    margin-top: var(--dl-space-space-threeunits);
    align-items: flex-start;
  }
  .home-text032 {
    color: #1f252c;
    font-size: 18px;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 500;
  }
  .home-container15 {
    width: 100%;
    height: 414px;
    display: flex;
    margin-top: var(--dl-space-space-threeunits);
    align-items: flex-start;
  }
  .home-container16 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  .home-container17 {
    flex: 0 0 auto;
    width: 319px;
    height: 48px;
    display: flex;
    align-self: center;
    align-items: center;
    border: 1px solid #524538;
    border-width: 1px;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
  }
  .home-text033 {
    color: #524538;
    font-size: 16px;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 700;
    letter-spacing: 0.24em;
  }
  .home-discount {
    flex: 0 0 auto;
    width: 100%;
    height: 288px;
    display: flex;
    align-items: flex-start;
    background-color: #eae3d9;
  }
  .home-container18 {
    flex: 0 0 auto;
    width: 45%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }
  .home-text034 {
    font-size: 3em;
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
  }
  .home-container19 {
    flex: 0 0 auto;
    width: 287px;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .home-container20 {
    flex: 0 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    background-size: cover;
    background-image: url('/playground_assets/disc-img1-1000w.png');
  }
  .home-container21 {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
  }
  .home-text037 {
    color: #1f252c;
    font-size: 16px;
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
  }
  .home-text038 {
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
    font-size: 18px;
  }
  .home-text040 {
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
    font-size: 18px;
  }
  .home-container22 {
    width: 100%;
    height: 52px;
    display: flex;
    align-items: flex-end;
    padding-right: 80px;
    cursor: pointer;
  }
  .home-text041 {
    color: #524538;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 600;
    letter-spacing: 0.24em;
  }
  .home-image05 {
    object-fit: cover;
    margin-left: var(--dl-space-space-halfunit);
  }
  .home-subsribe {
    width: 100%;
    height: 180px;
    display: flex;
    align-items: flex-start;
  }
  .home-container23 {
    flex: 0 0 auto;
    width: 45%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    background-color: #1f252c;
  }

  .home-text042 {
    color: #eae3d9;
    font-size: 36px;
    font-family: Lora;
    line-height: 1.25;
  }
  .home-container24 {
    flex: 0 0 auto;
    width: 35%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #151b22;
  }
  .home-text046 {
    color: #eae3d9;
    font-size: 24px;
    font-family: Montserrat;
    letter-spacing: 0.16em;
    width: 295px;
  }
  .home-text046:focus {
    outline: none;
  }
  .home-container25 {
    flex: 1;
    width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    background-color: #1f252c;
    cursor: pointer;
  }
  .home-text047 {
    color: #eae3d9;
    font-family: Montserrat;
    margin-right: var(--dl-space-space-halfunit);
    letter-spacing: 0.16em;
    cursor: pointer;
  }
  .home-image06 {
    object-fit: cover;
  }
  .product-showcase {
    transform: translateX(-110%);
    visibility: hidden;
    opacity: 0;
    transition: all 10s ease-out;
    height: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .pin-active {
    transform: translateX(0);
    height: 9rem !important;
    opacity: 1;
    visibility: visible;
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .pin-active-new {
    transform: translateX(0);
    height: 354px !important;
    opacity: 1;
    visibility: visible;
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  @media (max-width: 767px) {
    .home-container23,
    .home-container24,
    .home-container25 {
      width: 100%;
    }
  }

  @media only screen and (min-width: 90.063em) and (max-width: 120em) {
    .home-image {
      margin-top: -600px;
    }
    .home-banner-promo {
      width: auto;
    }
    .home-discount {
      margin-top: -2px;
    }
    .home-banner-promo {
      width: 100% !important;
    }
    .home-promo-title1 {
      width: auto;
      margin-right: 30px;
    }
    .home-promo-text {
      width: 589px;
    }
    .home-text016 {
      font-size: 20px;
    }
    .home-text012 {
      font-size: 3em;
    }
    .home-image03 {
      height: 104px;
    }
    .home-text020 {
      font-size: 20px;
    }
    .home-text014 {
      font-size: 3em;
    }
  }

  @media (max-width: 991px) {
    .bg-orange {
      border-radius: 0px 0px 0px 0px;
    }
    span.home-promo-note {
      display: block;
      color: #1f252c;
      font-family: Montserrat;
      font-weight: 400;
      z-index: 1;
      opacity: 0.6;
      padding-right: 16px;
      /* padding-left: 16px; */
    }
    .home-promo-desc {
      padding-bottom: 16px;
      padding-right: 16px;
      max-width: none;
    }
    .home-related-product {
      display: block;
      color: #eae3d9;
      font-family: Lora;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      margin-bottom: 9px;
      margin-left: -138px;
    }

    .home-container-heading {
      /* height: 120px; */
      margin-top: 30px;
    }

    .home-heading {
      font-size: 36px;
    }

    /* .home-row-hero {
    min-height: 340px;
  } */
    /* hero */
    .text-group-mobile {
      display: block;
    }
    .text-group-web {
      display: none;
    }
    .home-cover-bg {
      padding-top: var(--dl-space-space-threeunits);
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
    }
    .home-text,
    .home-text001,
    .home-text002 {
      font-size: 3em;
    }
    .home-col-text {
      z-index: 3;
    }
    .home-image {
      position: absolute;
      top: -175px;
      left: 0px;
      z-index: 2;
      margin-top: 0px;
      height: 100%;
    }
    .navbar-header {
      z-index: inherit;
    }
    .home-row-card {
      z-index: 3;
    }
    .inverse {
      z-index: 999;
    }

    /* promo spesial */
    /* .home-promo-detail{
    flex-wrap: wrap;
  }
  .home-product-group{
    display: none;
  }
  .home-image02{
    margin-left: 0px;
    height: 230px;
  } */

    /* discount */
    .home-discount {
      height: auto;
      padding: 16px;
      margin-top: -2px;
    }
    .home-discount {
      flex-wrap: wrap;
    }
    .home-container18 {
      flex-basis: 100%;
      margin-bottom: 16px;
    }
    .home-container19 {
      flex-basis: 48%;
      height: 200px;
      align-items: center;
    }

    .home-container20 {
      background-size: contain;
      background-position: center center;
      width: 100px;
    }
    .home-container21 {
      flex-basis: 50%;
      padding-left: 0px;
    }
    .home-text034 {
      font-size: 36px;
    }

    /* .home-promo-title,
  .home-promo-subtitle,
  .home-promo-desc {
    margin-left: 16px;
  } */

    .text-metime {
      display: block;
    }

    .home-image01 {
      right: -166px;
      top: -130px;
      width: 1300px;
    }

    .home-promo-subtitle {
      display: none;
    }
    .home-promo-title {
      display: flex;
      font-size: 36px;
      margin-bottom: 12px;
    }

    /* home-magazine */

    .home-magazine {
      flex-wrap: wrap;
      height: auto;
    }
    .home-container08 {
      flex-basis: 100%;
    }
    .home-container11 {
      flex-wrap: wrap;
    }
    .home-container12 {
      flex-basis: 100%;
      justify-content: flex-start;
      padding-left: 16px;
    }
    .home-container13 {
      flex-basis: 100%;
      justify-content: flex-start;
      padding-left: 16px;
    }
    .home-container14 {
      padding-left: 16px;
    }
    .home-container15 {
      flex-wrap: wrap;
      flex: 0 1 100%;
      justify-content: center;
    }
    .blog-card-blog-list {
      margin-bottom: 56px;
    }

    span.magazine-header-magazine-headertext {
      padding-left: 16 px;
    }

    /* subscribe */

    .home-subsribe {
      flex-wrap: wrap;
      height: auto;
      /* background: #151b22; */
    }
    .home-text042 {
      font-size: 23px;
      padding-left: 0px;
    }
    .home-text046 {
      font-size: 16px;
      width: 100%;
      padding: 14px 20px;
    }

    /* we love */
    .home-banner-hero {
      flex-wrap: wrap;
      flex: 0 1 100%;
      height: auto;
      padding: 16px;
      padding-bottom: 50px;
    }
    .home-container05 {
      width: 100%;
      height: auto;
      align-items: center;
      margin-top: 24px;
    }
    .home-text018 {
      text-align: center;
      width: 100%;
      margin-bottom: 16px;
    }
    .home-container07 {
      width: 100%;
      height: auto;
    }
    .home-text017 {
      text-align: center;
      margin-bottom: 24px;
    }
    .home-text017,
    .home-text018,
    .home-text019 {
      font-size: 32px;
      line-height: 42px;
    }
    .home-banner-gallery {
      flex-wrap: wrap;
      height: auto;
      row-gap: 0px;
      column-gap: 10px;
      padding-top: 32px;
      padding-bottom: 32px;
      margin-top: -1px;
    }
    .home-gallery-img-customer {
      margin: 0px;
      width: 100%;
      height: auto;
      padding: 16px;
    }
    .badge-customer {
      margin-left: 0px !important;
      width: 100%;
    }
    .home-gallery-img {
      width: 100%;
      padding-left: 16px;
    }
    .spacer-ads {
      display: none;
    }

    .home-title-gallery {
      padding-left: 0px;
      text-shadow: 2px 2px 8px rgb(0 0 0 / 40%);
    }

    .home-banner-promo {
      flex-wrap: wrap;
      height: auto;
      width: 100%;
      padding-top: 32px;
      padding-left: 16px;
    }
    .home-promo-img {
      flex-basis: 20%;
      align-self: flex-start;
    }
    .home-promo-title1 {
      flex-basis: 80%;
      margin-left: 0px;
      margin-right: 0px;
      height: auto;
      width: auto;
    }
    .home-text014 {
      display: none;
    }
    .home-promo-text {
      flex-basis: 100%;
      padding-left: 20%;
    }
    .home-container04 {
      flex-basis: 100%;
      align-items: center;
      justify-content: start;
      margin-top: 0px;
      margin-bottom: 24px;
    }
    .home-text014 {
      margin-bottom: 0px;
    }
    .home-text015 {
      font-size: 32px;
      margin-left: 0px;
    }
    .home-text012 {
      text-align: left;
      font-size: 2em;
    }
    .home-text016 {
      padding: 16px;
      padding-top: 8px;
      margin-bottom: 24px;
    }
    .home-best-seller-wrap {
      overflow: scroll;
      height: 314px;
      width: 100%;
    }
    .home-best-seller {
      position: absolute;
      bottom: 0;
      overflow-y: scroll;
      column-gap: 16px;
      padding: 0;
      height: 300px;
      justify-content: flex-start;
      width: auto;
    }
    .home-row-card {
      justify-content: flex-start;
      position: absolute;
      bottom: -40px;
      /* overflow: scroll; */
      height: 154px;
      padding-left: 16px;
      column-gap: 10px;
    }
    /* .home-card-transparent {
    display: none;
  } */

    .home-image02 {
      height: 326px;
      position: absolute;
      left: 424px;
      top: -254px;
    }
    .home-promo-detail {
      flex-wrap: wrap;
      height: 100%;
      overflow: hidden;
    }
    .home-text020 {
      text-align: center;
    }
    /* .home-wrap {
    flex-basis: 100%;
    height: 600px;
    padding-left: 0px;
    justify-content: flex-start;
    padding-top: 98px;
  } */
    .home-wrap1 {
      flex-basis: 100%;
      height: 600px;
      padding-right: unset;
      padding: 16px;
    }
    .home-product-group {
      position: relative;
      right: unset;
      align-items: center;
      width: 100%;
      margin-top: -24px;
      max-width: none;
    }
    .home-promo-list {
      padding: 0;
      position: relative;
      overflow: scroll;
    }
    .home-container03 {
      position: absolute;
      flex: 1;
      width: auto;
      height: 152px;
      background-color: #eae3d9;
      overflow-y: scroll;
      bottom: 0;
      display: flex;
      padding-left: 16px;
    }
    .product-card-wrap {
      min-width: 300px;
    }

    .home-container03 {
      height: 300px;
    }
    .home-promo-list {
      height: 300px;
    }
  }

  @media (max-width: 767px) {
    .home-red-box {
      height: 20px;
      width: 65%;
      bottom: 0;
      right: 32px;
      left: calc(20% + 32px);
    }
  }
</style>
