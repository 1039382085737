<template>
  <div class="spacer-h16-container" v-bind:class="rootClassName"></div>
</template>

<script>
export default {
  name: 'SpacerH16',
  props: {
    rootClassName: String,
  },
}
</script>

<style scoped>
.spacer-h16-container {
  height: var(--dl-size-size-xsmall);
}

</style>
