import Vue from 'vue';
import moment from 'moment';
Vue.prototype.$formatIDR = (value) => {
  if (typeof value === 'number') {
    return new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
    }).format(value);
  }
};

Vue.prototype.$formatDate = (date, format) => {
  if (typeof date === 'string') {
    return moment(date).format(format);
  }
};
