<template>
  <div>
    <div class="navbar-dropdown-nav-dropdown-bg pointer-events-none"></div>
    <div
      class="navbar-dropdown-nav-dropdown max-xl:px-5 xl:px-32 overflow-hidden"
      @mouseenter="dropDownEnter"
      @mouseleave="dropDownLeave"
    >
      <div class="max-w-rifyo mx-auto flex w-full">
        <div class="navbar-dropdown-nav-dropdown-menu">
          <span class="navbar-dropdown-item-menu title-tag cursor-default"
            >Shop</span
          >
          <span
            v-for="item in filteredItems"
            :key="item.id"
            :class="{ active: selected === item.id }"
            @click="openDetail(item)"
            class="navbar-dropdown-item-menu group-scoped"
          >
            <span
              :class="[
                'leading-none border-b-4 transition-all border-transparent group-scoped-hover:border-red-500',
                { 'border-red-500': selected === item.id },
              ]"
              >{{ item.title }}</span
            >
            <img
              alt="image"
              src="/playground_assets/arrow-right.svg"
              class="icon-right"
            />
          </span>
          <span
            @click="goPage('/shop/all')"
            class="navbar-dropdown-item-menu group-scoped"
          >
            <span
              class="leading-none border-b-4 transition-all border-transparent group-scoped-hover:border-red-500 font-medium"
              >Shop All</span
            >
          </span>
        </div>
        <div
          class="navbar-dropdown-nav-dropdown-content max-xl:-mr-14 xl:-mr-96"
        >
          <img
            alt="image"
            :src="navBarCover"
            class="bg-navbar w-full object-cover"
          />
          <div class="rows">
            <div
              v-for="item in products"
              :key="item.id"
              @click="goPageDetail(item)"
            >
              <dropdown-product
                :title="item.title"
                :item="item"
                :product_img_src="item.photo"
              ></dropdown-product>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import DropdownProduct from './dropdown-product';
  import SpacerW16 from './spacer-w16';
  import Api from '../api';
  export default {
    name: 'NavbarDropdown',
    components: {
      DropdownProduct,
      SpacerW16,
    },
    data() {
      return {
        categories: [],
        navBarCover: '/playground_assets/bg_navbar.png',
        products: [],
        selected: null,
      };
    },
    created: function () {
      this.getCategory();
    },
    methods: {
      dropDownEnter() {
        this.$store.commit('setNavBarDropDown', true);
      },
      dropDownLeave() {
        this.$store.commit('setNavBarDropDown', false);
        this.hide();
      },
      hide() {
        if (this.$store.state.isDropdown) {
          this.$store.commit('hideDropdown');
        }
        if (this.$route.name == 'Home' && !this.isScroll) {
          this.iSiconColor = '#fff';
        }
      },
      goPage(page) {
        this.$store.commit('setNavBarDropDown', false);
        this.$store.dispatch('hideDropdown');
        this.$store.commit('setTitlePage', 'Shop All');
        this.$store.commit('setShopAllCategory', { name: null });
        this.$router.push(page).then(() => {}).catch(() => {});;
      },
      goPageDetail(item) {
        // const response = await this.$http.get(Api.productDetailUrl+item.id);
        // console.log(item);
        this.$store.commit('setNavBarDropDown', false);
        this.$store.dispatch('hideDropdown');
        this.$router.push(`/shop/${item.slug}`).then(() => {}).catch(() => {});
        this.$store.commit('setTitlePage', item.title);
        this.$store.commit('setShopAllCategory', item);
        // this.$store.commit("setProduct", { ...item });
      },
      openDetail(item) {
        this.navBarCover = item.photo;
        this.products = item.child;
        this.selected = item.id;
      },
      async getCategory() {
        try {
          const response = await this.$http.get(Api.categoryUrl);
          this.categories = response.data.data;
          if (this.categories.length > 0) {
            this.navBarCover = this.categories[0].photo;
            this.products = this.categories[0].child;
            this.selected = this.categories[0].id;
          }
        } catch (error) {
          console.log(error);
        }
      },
    },
    computed: {
      isPopup() {
        return this.$store.state.isPopup;
      },
      filteredItems() {
        return this.categories.filter((item) => {
          return item.status === 'active';
        });
      },
    },
  };
</script>

<style scoped>
  .navbar-dropdown-nav-dropdown {
    width: 100%;
    padding-bottom: 32px;
    display: flex;
    align-items: flex-start;
    /* padding-left: var(--dl-space-space-fiveunits); */
    /* padding-right: var(--dl-space-space-fiveunits); */
    background-color: #fff;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 101;
  }

  .navbar-dropdown-nav-dropdown-bg {
    position: fixed;
    top: 200px;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.74);
    z-index: 10;
    overflow: hidden;
  }
  .navbar-dropdown-nav-dropdown-menu {
    flex: 0 0 auto;
    width: 230px;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .rows {
    margin-top: -145px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    column-gap: 20px;
    row-gap: 20px;
    flex-wrap: wrap;
    padding: 8%;
    padding-bottom: 32px;
    height: auto;
  }

  .bg-navbar {
    height: 220px;
  }

  .icon-right {
    height: 16px;
    width: 16px;
  }
  .active {
    font-weight: 700;
  }
  .navbar-dropdown-item-menu {
    font-family: Montserrat;
    font-weight: 500;
    color: #1f252c;
    font-size: 16px;
    padding-top: var(--dl-space-space-oneandhalfunits);
    display: flex;
    width: 100%;
    justify-content: space-between;
    cursor: pointer;
  }
  .navbar-dropdown-item-menu1 {
    padding-top: var(--dl-space-space-oneandhalfunits);
  }
  .navbar-dropdown-item-menu2 {
    padding-top: var(--dl-space-space-oneandhalfunits);
  }
  .navbar-dropdown-item-menu3 {
    padding-top: var(--dl-space-space-oneandhalfunits);
  }
  .navbar-dropdown-item-menu4 {
    padding-top: var(--dl-space-space-oneandhalfunits);
  }
  .navbar-dropdown-item-menu5 {
    padding-top: var(--dl-space-space-oneandhalfunits);
  }

  .title-tag {
    font-family: Lora;
    font-weight: 700;
    color: #1f252c;
    font-size: 18px;
    margin-bottom: 15px;
  }
  .navbar-dropdown-nav-dropdown-content {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: flex-start;
    /* padding-top: var(--dl-space-space-oneandhalfunits); */
    padding-left: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .navbar-dropdown-item-menu.active {
    font-weight: 700;
  }
</style>
