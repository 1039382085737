import Vue from 'vue';
import Vuex from 'vuex';
import cartModule from './modules/cart';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    isDropdown: false,
    isModal: false,
    isModalDetail: false,
    isPopup: true,
    isAuthenticated: false,
    user: null,
    token: null,
    product_detail: null,
    cart: [],
    carts: {
      product: [],
      product_indent: [],
    },
    shopAll: {
      category: {
        title: null,
      },
      dropDown: false,
    },
    titlePage: 'Shop All',
    navBar: {
      active: true,
      dropDown: false,
      dropDownMobile: null,
      shop: false,
    },
    screen: {
      width: screen.width,
    },
    productReview: null,
  },
  mutations: {
    setProductReview(state, payload) {
      state.productReview = payload;
    },
    setScreen(state, payload) {
      state.screen = payload;
    },
    setNavBarDropDownMobile(state, payload) {
      state.navBar.dropDownMobile = payload;
    },
    setNavBarShop(state, payload) {
      if (state.navBar.active) {
        state.navBar.shop = payload;
      }
    },
    setNavBarDropDown(state, payload) {
      if (state.navBar.active) {
        state.navBar.dropDown = payload;
      }
    },
    hideDropdown(state) {
      setTimeout(() => {
        if (!state.navBar.shop && !state.navBar.dropDown) {
          state.isDropdown = false;
        }
      }, 200);
    },
    setProduct(state, payload) {
      state.product_detail = payload;
    },
    showDropdown(state) {
      state.isDropdown = true;
    },
    toggleModal(state) {
      state.isModal = !state.isModal;
    },
    togglePopup(state) {
      state.isPopup = !state.isPopup;
    },
    hidePopup(state) {
      state.isPopup = false;
    },
    showPopup(state) {
      state.isPopup = true;
    },
    toggleModalDetail(state) {
      state.isModalDetail = !state.isModalDetail;
    },
    isAuthenticated(state, payload) {
      state.isAuthenticated = payload.isAuthenticated;
    },
    setCart(state, payload) {
      state.cart = payload.cart;
    },
    setGlobalCart(state, carts) {
      state.carts = carts;
    },
    setUser(state, payload) {
      localStorage.setItem('user', JSON.stringify(payload));
      localStorage.setItem('token', JSON.stringify(payload.token));
      state.user = payload;
      state.token = payload.token;
    },
    setProfile(state, data) {
      state.user = data;
      localStorage.setItem('user', JSON.stringify(data));
    },
    logout() {
      clearStorage();
    },
    setShopAllCategory(state, payload) {
      state.shopAll.category = payload;
    },
    setTitlePage(state, title) {
      state.titlePage = title
    },
    clearUser(state) {
      state.user = null;
    },
  },
  getters: {
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
  },
  actions: {
    hideDropdown({ state }) {
      state.navBar.active = false;
      state.isDropdown = false;
      state.isModal = false;
      state.isModalDetail = false;
      state.isPopup = false;
      setTimeout(() => {
        state.navBar.active = true;
      }, 1000);
    },
    login(context, payload) {
      vueAuth.login(payload.user, payload.requestOptions).then((response) => {
        context.commit('isAuthenticated', {
          isAuthenticated: vueAuth.isAuthenticated(),
        });
      });
    },
  },
  modules: {
    cartCount: cartModule,
  },
});
export default store;
