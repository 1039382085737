<template>
  <div @click="openDetail()" class="menu-item-menu-item">
    <span class="menu-item-text">{{ title }}</span>
    <img :alt="image_alt2" :src="image_src2" class="menu-item-image" />
  </div>
</template>

<script>
export default {
  name: 'MenuItem',
  props: {
    image_src2: {
      type: String,
      default: '/playground_assets/arrow-right-black.svg',
    },
    image_alt2: {
      type: String,
      default: 'image',
    },
    title: {
      type: String,
      default: 'By Product',
    },
  },
  methods: {
    toggleModal() {
      this.$store.commit('toggleModal');
    },
    openDetail() {
      this.$store.commit('toggleModal');
      this.$store.commit('toggleModalDetail');
    },
  },
}
</script>

<style scoped>
.menu-item-menu-item {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.menu-item-image {
  width: 100px;
  object-fit: cover;
}
@media(max-width: 991px) {
  .menu-item-menu-item {
    height: 50px;
    align-items: center;
    justify-content: space-between;
  }
  .menu-item-text {
    font-style: normal;
    font-family: Montserrat;
    font-weight: 600;
  }
  .menu-item-image {
    width: 24px;
  }
}
</style>
