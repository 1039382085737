<template>
  <div
    @click="goPage()"
    class="
      bg-white
      lg:h-40
      max-lg:h-64 max-lg:flex-shrink-0
      flex
      lg:flex-1 lg:flex-row
      justify-center
      max-lg:flex-col
      items-center
      p-5
      z-20
      transition
      opacity-100
      hover:opacity-90
      max-lg:w-56
      cursor-pointer
    "
  >
    <img
      :alt="product_img_alt"
      :src="productImg"
      class="
        promotion-card-product-img
        object-contain
        mr-5
        max-lg:pb-5 max-lg:mb-auto
      "
    />
    <div class="flex flex-col max-lg:w-full items-start justify-center flex-1">
      <h3
        class="
          font-bold
          text-lg
          font-serif
          leading-tight
          mb-3
          line-clamp-3
          h-16
        "
      >
        {{ title }}
      </h3>
      <div class="flex justify-between items-center w-full">
        <p class="promotion-card-text3 m-0 leading-snug whitespace-nowrap">
          {{ text2 }}
        </p>
        <img :alt="image_alt" :src="image_src" class="promotion-card-image" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PromotionCard",
  methods: {
    goPage() {
      if (this.slug) {
        this.$router.push(`/product-detail/${this.slug}`);
      }
    },
  },
  props: {
    title: {
      type: String,
      default: "Cosm Chair, Glacier, Low Back, Fixed Arms",
    },
    text: {
      type: String,
      default: "IDR 1.350.000",
    },
    slug: {
      type: String,
    },
    text2: {
      type: String,
      default: "IDR 1.150.000",
    },
    image_alt: {
      type: String,
      default: "image",
    },
    productImg: {
      type: String,
      default: "/playground_assets/product1-1000w.png",
    },
    image_src: {
      type: String,
      default: "/playground_assets/arrow-right-black.svg",
    },
    product_img_alt: {
      type: String,
      default: "image",
    },
  },
};
</script>

<style scoped>
.promotion-card-promo-card {
  /* width: 311px; */
  height: 154px;
  display: flex;
  flex: 1;
  padding: var(--dl-space-space-oneandhalfunits);
  z-index: 1;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #ffffff;
}
.promotion-card-promo-card:hover {
  cursor: pointer;
}
.promotion-card-product-img {
  max-width: 114px;
  object-fit: contain;
  /* margin-right: var(--dl-space-space-oneandhalfunits); */
}
.promotion-card-container {
  height: 100%;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.promotion-card-text {
  color: #1f252c;
  font-size: 18px;
  font-style: normal;
  text-align: left;
  word-break: break-word;
  font-family: Lora;
  font-weight: 700;
}
.promotion-card-text1 {
  font-family: lora;
}
.promotion-card-container1 {
  width: 100%;
  height: 37px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.price-group {
  margin-top: 18px;
  display: flex;
  flex-direction: column;
}
.promotion-card-text2 {
  color: #1f252c;
  text-decoration: line-through;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 14px;
}
.promotion-card-text3 {
  color: #1f252c;
  font-style: normal;
  font-family: Montserrat;
  font-weight: normal;
  font-size: 18px;
}
.promotion-card-image {
  width: 24px;
  object-fit: cover;
  margin-left: var(--dl-space-space-halfunit);
}

@media (max-width: 991px) {
  .promotion-card-promo-card {
    margin-right: 10px;
    width: 219px;
    height: 280px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .promotion-card-container1 {
    margin-top: 8px;
  }
  .price-group {
    margin-top: 0px;
  }

  .promotion-card-text1 {
    font-size: 16px;
    font-family: Lora;
  }
  .promotion-card-text2 {
    display: none;
  }
}
</style>
